import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";
import _orderBy from "lodash.orderby";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    // login handler
    loginSuccess: false,
    loginError: false,
    usuarioLoggeado: [],
    // reuniones
    reuniones: [],
    lugares: [],
    clientes: [],
    departamentos: [],
    empresas: [],
    tipoContacto: [],
    confs: [],
    busqueda: "",
    // mostrar/ocultar password
    esTipoPassword: true,
    tipoInput: "password",
    // usuarios
    usuarios: [],
    responsables: [],
    baseClientes: []
  },
  mutations: {
    cambiarLoginSuccess: (state, success) => (state.loginSuccess = success),
    cambiarLoginError: (state, error) => (state.loginError = error),
    vaciarUsuarioLoggeado: state => (state.usuarioLoggeado = []),
    cambiarTipoPassword: state => (state.esTipoPassword = !state.esTipoPassword),
    cambiarTipoPasswordBoolean: (state, bool) => (state.esTipoPassword = bool),
    cambiarTipoInput: (state, param) => (state.tipoInput = param)
  },
  actions: {
    // carga reuniones
    axiosReuniones: ({ state }) => {
      let payload = {
        id_usuario: state.usuarioLoggeado.id_usuario,
        perfil: state.usuarioLoggeado.perfil
      };
      axios
        .post(`../api/api-toi/reuniones.php`, payload)
        .then(resp => {
          state.reuniones = resp.data;
          state.reuniones = _orderBy(state.reuniones, "id", "desc");
        })
        .catch(e => console.error(e));
    },
    // carga configuraciones
    axiosConfs: ({ state }) => {
      axios
        .get(`../api/api-toi/configuraciones.php`)
        .then(resp => (state.confs = resp.data))
        .catch(e => console.error(e));
    },
    // carga lugares
    axiosLugares: ({ state }) => {
      axios
        .get(`../api/api-toi/lugares.php`)
        .then(resp => (state.lugares = resp.data))
        .catch(e => console.error(e));
    },
    // carga departamentos
    axiosDepartamentos: ({ state }) => {
      axios
        .get(`../api/api-toi/departamentos.php`)
        .then(resp => (state.departamentos = resp.data))
        .catch(e => console.error(e));
    },
    // carga empresas
    axiosEmpresas: ({ state }) => {
      axios
        .get(`../api/api-toi/empresas.php`)
        .then(resp => (state.empresas = resp.data))
        .catch(e => console.error(e));
    },
    // carga responsables
    axiosResponsables: ({ state }) => {
      axios
        .get(`../api/api-toi/responsables.php`)
        .then(resp => (state.responsables = resp.data))
        .catch(e => console.error(e));
    },
    // carga base clientes
    axiosBaseClientes: ({ state }) => {
      axios
        .get(`../api/api-toi/base-clientes.php`)
        .then(resp => (state.baseClientes = resp.data))
        .catch(e => console.error(e));
    },
    // carga clientes
    axiosClientes: ({ state }) => {
      let payload = {
        id_usuario: state.usuarioLoggeado.id_usuario
      };
      axios
        .post(`../api/api-toi/clientes.php`, payload)
        .then(resp => {
          state.clientes = resp.data;
        })
        .catch(e => console.error(e));
    },
    // carga tipos de contacto
    axiosTipoContacto: ({ state }) => {
      axios
        .get(`../api/api-toi/tcontacto.php`)
        .then(resp => (state.tipoContacto = resp.data))
        .catch(e => console.error(e));
    },
    // carga usuarios
    axiosUsuarios: ({ state }) => {
      axios
        .get(`../api/api-toi/usuarios.php`)
        .then(resp => {
          state.usuarios = resp.data;
          state.usuarios = _orderBy(state.usuarios, "cod_empleado", "asc");
        })
        .catch(e => console.error(e));
    },
    // redirección a login
    redirectLogin: () => {
      router.push({ path: "/" });
    }
  }
});
