<!--eslint-disable-->
<template>
  <header>
    <div id="logo" @click="volverAInicio">
      <img id="logo-img" src="../assets/logo_pastores_600x300.png" />
      <p>Tu Opinión Importa!</p>
    </div>
    <nav>
      <!--nav desktop-->
      <div id="nav-desktop" v-if="loginSuccess">
        <router-link to="/nueva-reunion">
          <i class="fas fa-clipboard-list"></i> Nueva reunión
        </router-link>
        <router-link to="/reuniones">
          <i class="fas fa-tasks"></i> Reuniones
        </router-link>
        <router-link to="/graficas">
          <i class="fas fa-chart-bar"></i> Gráficas
        </router-link>
        <router-link to="/confs">
          <i class="fas fa-user-cog"></i> Ajustes
        </router-link>
        <router-link to="/" exact>
          <span :class="checkTipoUsuario" @click="cerrarSesion(false)">
            <i class="fas fa-sign-out-alt"></i>
            {{ usuarioLoggeado.login_usuario.toUpperCase() }}
          </span>
        </router-link>
      </div>

      <!--nav mobile-->
      <div id="nav-mobile" v-if="loginSuccess">
        <div id="nav-mobile__icon" @click="clickOpenMenu">
          <i class="fas fa-ellipsis-v"></i>
        </div>
        <div id="nav-mobile__menus" ref="navContenido">
          <div>
            <div id="nav-mobile__cerrar" @click="clickCloseMenu">
              <i class="far fa-times-circle"></i>
            </div>
            <div id="nav-mobile__enlaces">
              <ul>
                <li @click="clickCloseMenu">
                  <router-link to="/nueva-reunion">
                    <i class="fas fa-clipboard-list"></i> Nueva reunión
                  </router-link>
                </li>
                <li @click="clickCloseMenu">
                  <router-link to="/reuniones">
                    <i class="fas fa-tasks"></i> Reuniones
                  </router-link>
                </li>
                <li @click="clickCloseMenu">
                  <router-link to="/confs">
                    <i class="fas fa-user-cog"></i> Ajustes
                  </router-link>
                </li>
                <li @click="clickCloseMenu">
                  <router-link to="/" exact>
                    <span @click="cerrarSesion(false)">
                      <i class="fas fa-sign-out-alt"></i>
                      {{ usuarioLoggeado.login_usuario.toUpperCase() }}
                    </span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "AppHeader",
  computed: {
    ...mapState(["paginas", "loginSuccess", "usuarioLoggeado"]),
    checkTipoUsuario() {
      let tipoUs = this.usuarioLoggeado.perfil;
      switch (tipoUs) {
        case "root":
          return "usuario--root";
        case "admin":
          return "usuario--admin";
        case "resp":
          return "usuario--resp";
        default:
          return "usuario--tipo";
      }
    }
  },
  methods: {
    // nav-mobile
    clickOpenMenu() {
      $(this.$refs.navContenido).slideDown(200);
    },
    clickCloseMenu() {
      $(this.$refs.navContenido).slideUp(200);
    },
    volverAInicio() {
      router.push({ path: "/nueva-reunion" });
    },
    cerrarSesion(success) {
      this.$store.commit("cambiarLoginSuccess", success);
      this.$store.commit("vaciarUsuarioLoggeado");
    },
    cambiarLoginError(error) {
      this.$store.commit("cambiarLoginError", error);
    }
  }
};
</script>

<style scoped>
header {
  display: flex;
  padding: 10px;
}

#logo {
  position: relative;
  cursor: pointer;
}

#logo p {
  font-family: "Rubik", sans-serif;
  font-weight: 200;
  font-size: 2.15em;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(17, 47, 145, 1);
  letter-spacing: 0.02em;
}

#logo-img {
  width: 10rem;
}

nav {
  width: 100%;
  text-align: right;
}

nav a {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.9em;
  text-transform: uppercase;
  text-decoration: none;
  color: rgba(17, 47, 145, 0.45);
  margin: 0 15px;
  letter-spacing: 0.7px;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

nav a:hover {
  color: rgba(17, 47, 145, 0.75);
}

nav a.router-link-active {
  color: rgba(17, 47, 145, 1);
}

.usuario--tipo {
  color: inherit;
}
.usuario--root {
  color: #e7a413;
}
.usuario--admin {
  color: #e7a413;
}
.usuario--resp {
  color: #ec2f36;
}

/* nav-mobile css ====================
=================================== */
#nav-mobile__menus {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100%;
  background-color: rgba(17, 47, 145, 0.97);
  padding: 45px 30px;
  scroll-behavior: smooth;
  z-index: 1000;
}

#nav-mobile__icon {
  color: rgba(17, 47, 145, 0.95);
  padding: 0.5em;
  font-size: 1.5em;
}

#nav-mobile__menus div {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  font-size: 1.2em;
}

#nav-mobile__cerrar {
  grid-column: 1 / 1;
  grid-row: 1 / 2;
  color: aliceblue;
  text-align: right;
  row-gap: 6rem;
}

#nav-mobile__enlaces {
  grid-column: 1 / 1;
  grid-row: 2 / 3;
  padding: 0;
}

#nav-mobile__enlaces ul {
  list-style: none;
}

#nav-mobile__enlaces li {
  width: 100%;
  margin: 0;
  padding: 20px 0;
  border-bottom: 1px solid aliceblue;
}

#nav-mobile__enlaces li a {
  width: 100%;
  margin: 0;
  padding: 10px 0;
  text-align: right;
  color: aliceblue;
}

#nav-mobile__enlaces li:last-child {
  border-bottom: none;
}
</style>
