import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import $ from "jquery";
window.$ = $;
import VueSweetalert2 from "vue-sweetalert2";
import Vuesax from "vuesax";

import "vuesax/dist/vuesax.css";

Vue.config.productionTip = false;

// opciones color swal
const options = {
  confirmButtonColor: "#ff7674",
  cancelButtonColor: "#aaa"
};

// vue use
Vue.use(VueSweetalert2, options, Vuesax);

// instancia principal DOM virtual
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
