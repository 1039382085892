<!--eslint-disable-->
<template>
  <section class="seccion-componente">
    <div class="nombre-seccion">Reunion</div>

    <div class="contenido-seccion">
      <div class="todavia" v-if="this.reuniones.length == 0">
        Hola {{ this.usuarioLoggeado.nombre }}! Aún no has creado ninguna reunión...
        <br />
        <span id="crea-nueva-reunion" @click="enrutaNuevaReunion">
          <strong>> Crear mi primera reunión</strong>
        </span>
      </div>
      <div id="div-reuniones" v-if="this.reuniones.length > 0">
        <!--menu filtrado reuniones-->
        <div class="filtrar-reuniones">
          <div class="filtrar__cabecera" @click="mostrarContenido">
            <i class="fas fa-filter"></i> Filtrar reuniones
            <span
              id="info-filtrado"
              v-show="busqueda !== '' || fechaDesde !== '' || fechaHasta !== ''"
            >Las reuniones están siendo filtradas...</span>
          </div>
          <div v-if="mostrarContenido" class="filtrar__contenido" ref="divContenidoFiltrado">
            <form id="form-filtrado" class="form" autocomplete="off">
              <!--nombre reunión-->
              <p class="field">
                <label class="label" for="bq_clienteR">Filtra reuniones por cliente</label>
                <input
                  class="text-input input-filtro"
                  id="bq_clienteR"
                  name="bq_clienteR"
                  type="search"
                  v-model="busquedaActual"
                  @input="actualizarBusqueda"
                />
              </p>
              <!--fecha desde-->
              <p class="field half">
                <label class="label" for="bq_fecha_desde">Fecha desde</label>
                <input
                  class="text-input"
                  id="bq_fecha_desde"
                  name="bq_fecha_desde"
                  type="date"
                  v-model="fechaDesde"
                />
              </p>
              <!--fecha hasta-->
              <p class="field half">
                <label class="label" for="bq_fecha_hasta">Fecha hasta</label>
                <input
                  class="text-input"
                  id="bq_fecha_hasta"
                  name="bq_fecha_hasta"
                  type="date"
                  v-model="fechaHasta"
                />
              </p>
            </form>
            <a id="btn-cerrar-filtro" @click.prevent="cerrarContenido">
              <i class="far fa-times-circle"></i> cerrar
            </a>
          </div>
        </div>

        <!--ordenar reuniones-->
        <section id="reunion-order">
          <div class="item-order id-order" @click="ordenarReunionesId">
            <i class="fas fa-chevron-down" v-if="this.ordenAscId"></i>
            <i class="fas fa-chevron-up" v-else></i>
            <span>&nbsp;ID</span>
          </div>
          <div class="item-order fecha-order" @click="ordenarReunionesFch">
            <i class="fas fa-chevron-down" v-if="this.ordenAscFch"></i>
            <i class="fas fa-chevron-up" v-else></i>
            <span>&nbsp;FECHA</span>
          </div>
          <div class="item-order hora-order">
            <span></span>
          </div>
          <div class="item-order nombre-order" @click="ordenarReunionesNom">
            <i class="fas fa-chevron-down" v-if="this.ordenAscNom"></i>
            <i class="fas fa-chevron-up" v-else></i>
            <span>&nbsp;NOMBRE</span>
          </div>
          <div class="item-order pt-order" @click="ordenarReunionesPtn">
            <i class="fas fa-chevron-down" v-if="this.ordenAscPtn"></i>
            <i class="fas fa-chevron-up" v-else></i>
            <span>&nbsp;PT</span>
          </div>
        </section>

        <transition-group name="flip-list" tag="div">
          <reunion v-for="reunion in filtrarReuniones" :key="reunion.id" :reunion="reunion"></reunion>
        </transition-group>
      </div>
    </div>
    <!--botón top-->
    <div id="top" class="btn-inferiores" v-if="this.reuniones.length > 0" @click="scrollTop">
      <i class="fas fa-chevron-up"></i>
    </div>
    <!--boton export-->
    <div
      id="export"
      class="btn-inferiores"
      v-if="this.reuniones.length > 0"
      @click="exportReuniones"
    >
      <i class="fas fa-file-excel"></i>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import Reunion from "@/components/Reunion.vue";
import { mapState } from "vuex";
import router from "@/router";
import $ from "jquery";
import _orderBy from "lodash.orderby";
//import exportFromJSON from "export-from-json";

export default {
  name: "Reuniones",
  data() {
    return {
      busquedaActual: "",
      ordenAscId: false,
      ordenAscFch: false,
      ordenAscNom: false,
      ordenAscPtn: false,
      fechaDesde: "",
      fechaHasta: ""
    };
  },
  components: { Reunion },
  created() {
    this.estaLoggeado();
    this.scrollTop();
    this.axiosConfs();
    this.axiosReuniones();
  },
  computed: {
    ...mapState(["reuniones", "busqueda", "confs", "usuarioLoggeado"]),
    /**
     * filtrar reuniones por cliente, fecha desde y fecha hasta
     * */
    filtrarReuniones() {
      let reuns = this.reuniones;
      // Filtrado si los 3 campos llevan datos
      if (
        this.busquedaActual != "" &&
        (this.fechaDesde != "" && this.fechaHasta != "")
      ) {
        let resp = Object.keys(reuns)
          .map(key => reuns[key])
          .filter(
            reu =>
              new Date(reu.dateR).getTime() >=
                new Date(this.fechaDesde).getTime() &&
              new Date(reu.dateR).getTime() <=
                new Date(this.fechaHasta).getTime() &&
              reu.clienteR
                .toLowerCase()
                .includes(this.busquedaActual.toLowerCase())
          );
        return resp;
        // Filtrado si sólo el cliente lleva datos
      } else if (this.busquedaActual != "") {
        let resp = Object.keys(reuns)
          .map(key => reuns[key])
          .filter(reu =>
            reu.clienteR
              .toLowerCase()
              .includes(this.busquedaActual.toLowerCase())
          );
        return resp;
        // Filtrado si sólo las fechas llevan datos
      } else if (
        this.busquedaActual == "" &&
        (this.fechaDesde != "" && this.fechaHasta != "")
      ) {
        let resp = Object.keys(reuns)
          .map(key => reuns[key])
          .filter(
            reu =>
              new Date(reu.dateR).getTime() >=
                new Date(this.fechaDesde).getTime() &&
              new Date(reu.dateR).getTime() <=
                new Date(this.fechaHasta).getTime()
          );
        return resp;
        // Sin filtrar, devuleve el array reuniones completo
      } else {
        return this.reuniones;
      }
    }
  },
  methods: {
    // si el usuario no está loggeado lo redirecciona a '/'
    estaLoggeado() {
      if (!this.$store.state.loginSuccess) {
        this.$store.dispatch("redirectLogin");
      }
    },
    // carga reuniones
    axiosReuniones() {
      this.$store.dispatch("axiosReuniones");
    },
    // carga confs
    axiosConfs() {
      this.$store.dispatch("axiosConfs");
    },
    // mostrar/ocultar contenido filtrado
    mostrarContenido() {
      $(this.$refs.divContenidoFiltrado).slideToggle(300);
    },
    // ocultar contenido filtrado
    cerrarContenido() {
      $(this.$refs.divContenidoFiltrado).slideUp(300);
    },
    actualizarBusqueda() {
      this.$store.state.busqueda = this.busquedaActual;
    },
    fechaHoy() {
      let d = new Date(),
        mes = "" + (d.getMonth() + 1),
        dia = "" + d.getDate(),
        year = d.getFullYear();

      if (mes.length < 2) mes = "0" + mes;
      if (dia.length < 2) dia = "0" + dia;

      return [year, mes, dia].join("");
    },
    exportReuniones() {
      // convert To CSV : Dar formato al objeto como un CSV
      function formatearAcsv(objArray) {
        const array =
          typeof objArray != "object" ? JSON.parse(objArray) : objArray;
        let str = "";
        for (let i = 0; i < array.length; i++) {
          let line = "";
          for (let index in array[i]) {
            if (line != "") line += ";";
            line += array[i][index];
          }
          str += line + "\r\n";
        }
        return str;
      }
      // export CSV File
      function exportCSVFile(headers, items, fileName) {
        if (headers) {
          items.unshift(headers);
        }
        const jsonObject = JSON.stringify(items);
        const csv = formatearAcsv(jsonObject);
        const exportName = fileName + ".csv" || "export.csv";

        const link = window.document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8,%EF%BB%BF" + csv
        );
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      const headers = {
        id: "id",
        usu_creador: "Usuario Creador",
        usu_responsable: "Usuario Responsable",
        dateR: "fecha y hora",
        nombreR: "Nombre Reunion",
        clienteR: "Cliente",
        lugarR: "Lugar de la Reunion",
        solicitaR: "Solicita la Reunion",
        informeR: "Informe de la Reunion",
        tContactoR: "Tipo de Contacto",
        puntuacionR: "Impresion de la Reunion",
        observacionesR: "Observaciones"
      };

      const datosCompletos = () => {
        let r = this.$store.state.reuniones;
        let resp = [];
        for (const i of r) {
          switch (i.puntuacionR) {
            case "M":
              i.puntuacionR = "MALA";
              break;
            case "R":
              i.puntuacionR = "REGULAR";
              break;
            case "B":
              i.puntuacionR = "BUENA";
              break;
            case "MB":
              i.puntuacionR = "MUY BUENA";
              break;
            case "E":
              i.puntuacionR = "EXCELENTE";
              break;
          }
          if (Object.keys(i.observacionesR).length === 0) {
            i.observacionesR = "No constan observaciones";
            resp.push(i);
          } else {
            let observaciones = "";
            let indice = 0;
            for (let o = 0; o < Object.keys(i.observacionesR).length; o++) {
              indice = o + 1;
              observaciones += `Observación ${indice}:  ${
                i.observacionesR[o]["observacion"]
              }. `;
            }
            i.observacionesR = observaciones;
            resp.push(i);
          }
        }
        return resp;
      };

      const data = _orderBy(datosCompletos(), "id", "asc");

      exportCSVFile(
        headers,
        data,
        `csv_export_reuniones_${
          this.usuarioLoggeado.id_usuario
        }_${this.fechaHoy()}`
      );

      this.axiosReuniones();
    },
    scrollTop() {
      window.scroll({
        top: 0,
        behavior: "smooth"
      });
    },
    // ordenar reuniones
    ordenarReunionesId() {
      let dir;
      if (this.ordenAscId) {
        dir = "asc";
      } else {
        dir = "desc";
      }
      this.$store.state.reuniones = _orderBy(
        this.$store.state.reuniones,
        "id",
        dir
      );
      this.ordenAscId = !this.ordenAscId;
    },
    ordenarReunionesFch() {
      let dir;
      if (this.ordenAscFch) {
        dir = "asc";
      } else {
        dir = "desc";
      }
      this.$store.state.reuniones = _orderBy(
        this.$store.state.reuniones,
        "dateR",
        dir
      );
      this.ordenAscFch = !this.ordenAscFch;
    },
    ordenarReunionesNom() {
      let dir;
      if (this.ordenAscNom) {
        dir = "asc";
      } else {
        dir = "desc";
      }
      this.$store.state.reuniones = _orderBy(
        this.$store.state.reuniones,
        "nombreR",
        dir
      );
      this.ordenAscNom = !this.ordenAscNom;
    },
    ordenarReunionesPtn() {
      let dir;
      if (this.ordenAscPtn) {
        dir = "asc";
      } else {
        dir = "desc";
      }
      this.$store.state.reuniones = _orderBy(
        this.$store.state.reuniones,
        "puntuacionR",
        dir
      );
      this.ordenAscPtn = !this.ordenAscPtn;
    },
    enrutaNuevaReunion() {
      router.push("/nueva-reunion");
    }
  }
  /* filters: {
    soloNombre: t => {
      let resp = t.split(",");
      return resp[1].trim();
    }
  } */
};
</script>

<style scoped>
.todavia {
  width: 100%;
  border: 2px dashed rgb(17, 189, 186);
  text-align: center;
  font-size: 1.5em;
  color: rgb(17, 189, 186);
  padding: 1em;
  background-color: rgba(17, 189, 186, 0.16);
}

#crea-nueva-reunion {
  cursor: pointer;
}

.flip-list-move {
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}

.filtrar-reuniones {
  width: 100%;
  margin-bottom: 2px;
}

.filtrar__cabecera {
  padding: 0.7em;
  background: rgb(17, 189, 186);
  color: rgba(17, 47, 145, 1);
  cursor: pointer;
  font-size: 0.9em;
  font-weight: bold;
}

.filtrar__contenido {
  display: none;
  background-color: rgba(17, 189, 186, 0.3);
  padding: 0 80px 16px 80px;
  overflow: hidden;
}

#btn-cerrar-filtro {
  position: relative;
  float: right;
  bottom: -5px;
  right: -65px;
  cursor: pointer;
  color: rgba(17, 47, 145, 0.8);
  font-size: 0.95em;
}

#info-filtrado {
  float: right;
  font-style: italic;
  color: rgba(17, 47, 145, 0.8);
}

/* Botones inferiores */
.btn-inferiores {
  width: 64px;
  width: 4rem;
  height: 64px;
  height: 4rem;
  position: fixed;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 11.2px;
  padding: 0.7rem;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  box-shadow: 0 1px 12px 0 rgba(17, 47, 145, 0.225);
  z-index: 900;
}

/* botón export */
div#export {
  bottom: 20px;
  right: 20px;
  background-color: rgb(17, 189, 186);
  cursor: pointer;
}

div#export i {
  color: rgba(17, 47, 145, 1);
  font-size: 1.2em;
}

/* botón tools (mobile) */
.btn-inferiores--tools {
  background-color: rgba(17, 47, 145, 0.75);
}

div#tools {
  bottom: 20px;
  right: 20px;
  background-color: rgb(17, 189, 186);
  cursor: pointer;
}

div#tools i {
  color: rgba(17, 47, 145, 1);
  font-size: 1.2em;
}

div#tools--desplegado {
  display: none;
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100vw;
  height: 25%;
  background-color: rgb(17, 189, 186);
  padding: 48px 32px;
  padding: 3rem 2rem;
  color: white;
  scroll-behavior: smooth;
  z-index: 1000;
}

div#tools--desplegado i {
  font-size: 1.2em;
}

#btn-cerrar--tools {
  position: absolute;
  padding: 16px;
  padding: 1rem;
  top: 0px;
  right: 0px;
  color: rgba(17, 47, 145, 1);
}

#btn-tools--export {
  position: absolute;
  top: 40px;
  top: 2.5rem;
  left: 40px;
  left: 2.5rem;
  cursor: pointer;
}

#btn-tools--order {
  position: absolute;
  top: 40px;
  top: 2.5rem;
  left: 160px;
  left: 10rem;
  cursor: pointer;
}

/* botón top */
div#top {
  bottom: 90px;
  right: 20px;
  background-color: rgb(14, 189, 233);
  cursor: pointer;
}

div#top i {
  color: rgba(17, 47, 145, 1);
  font-size: 1.2em;
}

section#reunion-order {
  width: 100%;
  margin-bottom: 2px;
  padding: 4px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2fr 1fr 12fr 72px;
  grid-template-columns: 1fr 2fr 1fr 12fr 72px;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
  -webkit-column-gap: 5px;
  -moz-column-gap: 5px;
  column-gap: 5px;
  background-color: rgba(17, 189, 186, 0.15);
  font-size: 0.7em;
  color: rgba(17, 47, 145, 1);
}

div.item-order {
  padding: 0 0 0 2px;
  text-align: left;
  cursor: pointer;
}

div.id-order {
  grid-column: 1/2;
  grid-row: 1/1;
}

div.fecha-order {
  grid-column: 2/3;
  grid-row: 1/1;
  padding-left: 6px;
}

div.hora-order {
  grid-column: 3/4;
  grid-row: 1/1;
  padding-left: 2px;
}

div.nombre-order {
  grid-column: 4/5;
  grid-row: 1/1;
  padding-left: 4px;
}

div.pt-order {
  grid-column: 5/6;
  grid-row: 1/1;
  padding-left: 0;
}
</style>
