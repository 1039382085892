<template>
  <section class="seccion-componente">
    <div class="nombre-seccion">Gráficas</div>

    <div class="contenido-seccion">
      <div class="todavia" v-if="this.reuniones.length == 0">
        Hola {{ this.usuarioLoggeado.nombre }}! Debes crear alguna reunión para usar las gráficas...
        <br />
        <span id="crea-nueva-reunion" @click="enrutaNuevaReunion">
          <strong>> Crear mi primera reunión</strong>
        </span>
      </div>
      <section>
        <div class="nombre-seccion nombre-grafica">Número de reuniones (Reuniones/Mes)</div>
        <canvas id="grafica-reuniones1"></canvas>
        <div
          class="nombre-seccion nombre-grafica"
        >Impresión en las reuniones (Reuniones con valor/Mes)</div>
        <canvas id="grafica-reuniones2"></canvas>
      </section>
    </div>
  </section>
</template>

<script>
import Chart from "chart.js";
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "Graficas",
  mounted() {
    this.estaLoggeado();
    this.axiosReuniones();
    this.crearGrafica("grafica-reuniones1", this.reuniones1Data);
    this.crearGrafica("grafica-reuniones2", this.reuniones2Data);
  },
  data() {
    return {
      reuniones1Data: {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          datasets: [
            // número de reuniones mensuales
            {
              label: "Número de reuniones mensuales",
              data: [
                this.reuMes("01"),
                this.reuMes("02"),
                this.reuMes("03"),
                this.reuMes("04"),
                this.reuMes("05"),
                this.reuMes("06"),
                this.reuMes("07"),
                this.reuMes("08"),
                this.reuMes("09"),
                this.reuMes("10"),
                this.reuMes("11"),
                this.reuMes("12")
              ],
              backgroundColor: [
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)",
                "rgba(231, 164, 19,.4)"
              ],
              borderColor: [
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)",
                "rgba(231, 164, 19, 1)"
              ],
              borderWidth: 4
            },
            // Reuniones por iniciativa propia
            {
              label: "Reuniones por iniciativa propia",
              data: [
                this.reuSolicita("01", "Propia"),
                this.reuSolicita("02", "Propia"),
                this.reuSolicita("03", "Propia"),
                this.reuSolicita("04", "Propia"),
                this.reuSolicita("05", "Propia"),
                this.reuSolicita("06", "Propia"),
                this.reuSolicita("07", "Propia"),
                this.reuSolicita("08", "Propia"),
                this.reuSolicita("09", "Propia"),
                this.reuSolicita("10", "Propia"),
                this.reuSolicita("11", "Propia"),
                this.reuSolicita("12", "Propia")
              ],
              backgroundColor: [
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)",
                "rgba(17, 47, 145, .5)"
              ],
              borderColor: [
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)",
                "rgba(17, 47, 145, .8)"
              ],
              borderWidth: 4
            },
            // Reuniones por demanda del cliente
            {
              label: "Reuniones demandadas por el cliente",
              data: [
                this.reuSolicita("01", "Cliente"),
                this.reuSolicita("02", "Cliente"),
                this.reuSolicita("03", "Cliente"),
                this.reuSolicita("04", "Cliente"),
                this.reuSolicita("05", "Cliente"),
                this.reuSolicita("06", "Cliente"),
                this.reuSolicita("07", "Cliente"),
                this.reuSolicita("08", "Cliente"),
                this.reuSolicita("09", "Cliente"),
                this.reuSolicita("10", "Cliente"),
                this.reuSolicita("11", "Cliente"),
                this.reuSolicita("12", "Cliente")
              ],
              backgroundColor: [
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)",
                "rgba(17, 189, 186, 0.3)"
              ],
              borderColor: [
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)",
                "rgba(17, 189, 186, 1)"
              ],
              borderWidth: 4
            }
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      },
      reuniones2Data: {
        type: "bar",
        data: {
          labels: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre"
          ],
          datasets: [
            // Reuniones Malas
            {
              label: "Malas",
              data: [
                this.reuPuntuacion("01", "M"),
                this.reuPuntuacion("02", "M"),
                this.reuPuntuacion("03", "M"),
                this.reuPuntuacion("04", "M"),
                this.reuPuntuacion("05", "M"),
                this.reuPuntuacion("06", "M"),
                this.reuPuntuacion("07", "M"),
                this.reuPuntuacion("08", "M"),
                this.reuPuntuacion("09", "M"),
                this.reuPuntuacion("10", "M"),
                this.reuPuntuacion("11", "M"),
                this.reuPuntuacion("12", "M")
              ],
              backgroundColor: [
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)",
                "rgba(255, 0, 0, 0.5)"
              ],
              borderColor: [
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)",
                "rgba(255, 0, 0, 0.8)"
              ],
              borderWidth: 4
            },
            // Reuniones Regulares
            {
              label: "Reuniones Regulares",
              data: [
                this.reuPuntuacion("01", "R"),
                this.reuPuntuacion("02", "R"),
                this.reuPuntuacion("03", "R"),
                this.reuPuntuacion("04", "R"),
                this.reuPuntuacion("05", "R"),
                this.reuPuntuacion("06", "R"),
                this.reuPuntuacion("07", "R"),
                this.reuPuntuacion("08", "R"),
                this.reuPuntuacion("09", "R"),
                this.reuPuntuacion("10", "R"),
                this.reuPuntuacion("11", "R"),
                this.reuPuntuacion("12", "R")
              ],
              backgroundColor: [
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)",
                "rgba(184, 134, 11, 0.3)"
              ],
              borderColor: [
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)",
                "rgba(184, 134, 11, 1)"
              ],
              borderWidth: 4
            },
            // Reuniones Buenas
            {
              label: "Reuniones Buenas",
              data: [
                this.reuPuntuacion("01", "B"),
                this.reuPuntuacion("02", "B"),
                this.reuPuntuacion("03", "B"),
                this.reuPuntuacion("04", "B"),
                this.reuPuntuacion("05", "B"),
                this.reuPuntuacion("06", "B"),
                this.reuPuntuacion("07", "B"),
                this.reuPuntuacion("08", "B"),
                this.reuPuntuacion("09", "B"),
                this.reuPuntuacion("10", "B"),
                this.reuPuntuacion("11", "B"),
                this.reuPuntuacion("12", "B")
              ],
              backgroundColor: [
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)",
                "rgba(0, 191, 255, 0.3)"
              ],
              borderColor: [
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)",
                "rgba(0, 191, 255, 0.8)"
              ],
              borderWidth: 4
            },
            // Reuniones Muy Buenas
            {
              label: "Reuniones Muy Buenas",
              data: [
                this.reuPuntuacion("01", "MB"),
                this.reuPuntuacion("02", "MB"),
                this.reuPuntuacion("03", "MB"),
                this.reuPuntuacion("04", "MB"),
                this.reuPuntuacion("05", "MB"),
                this.reuPuntuacion("06", "MB"),
                this.reuPuntuacion("07", "MB"),
                this.reuPuntuacion("08", "MB"),
                this.reuPuntuacion("09", "MB"),
                this.reuPuntuacion("10", "MB"),
                this.reuPuntuacion("11", "MB"),
                this.reuPuntuacion("12", "MB")
              ],
              backgroundColor: [
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)",
                "rgba(50, 205, 50, 0.3)"
              ],
              borderColor: [
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)",
                "rgba(50, 205, 50, 0.8)"
              ],
              borderWidth: 4
            },
            // Reuniones Excelentes
            {
              label: "Reuniones Excelentes",
              data: [
                this.reuPuntuacion("01", "E"),
                this.reuPuntuacion("02", "E"),
                this.reuPuntuacion("03", "E"),
                this.reuPuntuacion("04", "E"),
                this.reuPuntuacion("05", "E"),
                this.reuPuntuacion("06", "E"),
                this.reuPuntuacion("07", "E"),
                this.reuPuntuacion("08", "E"),
                this.reuPuntuacion("09", "E"),
                this.reuPuntuacion("10", "E"),
                this.reuPuntuacion("11", "E"),
                this.reuPuntuacion("12", "E")
              ],
              backgroundColor: [
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)",
                "rgba(255, 215, 0, 0.3)"
              ],
              borderColor: [
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)",
                "rgba(255, 215, 0, 0.8)"
              ],
              borderWidth: 4
            }
          ]
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          }
        }
      }
    };
  },
  computed: {
    ...mapState(["reuniones", "usuarioLoggeado"])
  },
  methods: {
    // si el usuario no está loggeado lo redirecciona a "/"
    estaLoggeado() {
      if (!this.$store.state.loginSuccess) {
        this.$store.dispatch("redirectLogin");
      }
    },
    // carga reuniones
    axiosReuniones() {
      this.$store.dispatch("axiosReuniones");
    },
    // crear gráfica
    crearGrafica(chartId, chartData) {
      const ctx = document.getElementById(chartId);
      // eslint-disable-next-line
      const myChart = new Chart(ctx, {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      });
    },
    // Datos para las gráficas
    // reuniones por mes
    reuMes(m) {
      let count = 0;
      this.$store.state.reuniones.filter(f => {
        if (f.dateR.substr(5, 2) == m) {
          count++;
        }
      });
      return Number(count);
    },
    // solicita reunión...
    reuSolicita(m, s) {
      let count = 0;
      this.$store.state.reuniones.filter(f => {
        if (f.dateR.substr(5, 2) == m && f.solicitaR == s) {
          count++;
        }
      });
      return Number(count);
    },
    // puntuacion reunión...
    reuPuntuacion(m, p) {
      let count = 0;
      this.$store.state.reuniones.filter(f => {
        if (f.dateR.substr(5, 2) == m && f.puntuacionR == p) {
          count++;
        }
      });
      return Number(count);
    },
    enrutaNuevaReunion() {
      router.push("/nueva-reunion");
    }
  }
  /* filters: {
    soloNombre: t => {
      let resp = t.split(",");
      return resp[1].substr(1, resp[1].length);
    }
  } */
};
</script>

<style scoped>
.nombre-grafica {
  width: 100%;
  text-align: center;
  color: rgba(17, 189, 186, 1);
  background-color: rgba(17, 189, 186, 0.16);
  padding: 0.6em;
  margin-bottom: 0.7em;
}

.contenido-seccion canvas {
  padding-bottom: 0.3em;
}

.todavia {
  width: 100%;
  border: 2px dashed rgb(17, 189, 186);
  text-align: center;
  font-size: 1.5em;
  color: rgb(17, 189, 186);
  padding: 1em;
  background-color: rgba(17, 189, 186, 0.16);
}

#crea-nueva-reunion {
  cursor: pointer;
}
</style>