<!-- eslint-disable -->
<template>
  <section class="seccion-componente">
    <div class="nombre-seccion">Configuraciones</div>

    <div id="confs" class="contenido-seccion">
      <div id="div-configuraciones">
        <div class="panel-confs">
          <!---->
          <!---->
          <!---->
          <!--Mis Datos-->
          <div class="panel-confs__usuario">
            <div class="panel-confs__cabecera" @click="mostrarContenido($refs.divConfs1)">
              <h4>Mis datos</h4>
            </div>
            <div class="panel-confs__contenido" ref="divConfs1">
              <form
                id="form-ajustes-usuario"
                class="form"
                autocomplete="off"
                @submit.prevent="actualizarDatos('misdatos')"
              >
                <!--nombre-->
                <p class="field required">
                  <label class="label" for="nombre_usuario">Nombre</label>
                  <input
                    class="text-input"
                    id="nombre_usuario"
                    name="nombre_usuario"
                    type="text"
                    v-model="nombre"
                  />
                </p>

                <!--email-->
                <p class="field required">
                  <label class="label" for="email_usuario">Email</label>
                  <input
                    class="text-input"
                    id="email_usuario"
                    name="email_usuario"
                    type="text"
                    v-model="email"
                  />
                </p>

                <!--usuario-->
                <p class="field half required">
                  <label class="label" for="usuario_usuario">Usuario</label>
                  <input
                    class="text-input"
                    id="login_usuario"
                    name="login_usuario"
                    type="text"
                    v-model="login_usuario"
                  />
                </p>

                <!--pass-->
                <p class="field half required">
                  <label class="label" for="pass_usuario">Contraseña</label>
                  <input
                    class="text-input"
                    id="pass_usuario"
                    name="pass_usuario"
                    :type="tipoInput"
                    v-model="pass_usuario"
                  />
                </p>
                <i class="fas fa-eye eye" @click="cambiarTipoInput" v-show="this.esTipoPassword"></i>
                <i
                  class="fas fa-eye-slash eye"
                  @click="cambiarTipoInput"
                  v-show="!this.esTipoPassword"
                ></i>

                <button class="button" type="submit">
                  <i class="far fa-save"></i>&nbsp;&nbsp;Guardar mis datos
                </button>
              </form>
            </div>
          </div>
          <!--...fin Mis Datos-->

          <!---->
          <!---->
          <!---->
          <!--Gestion de usuarios (perfiles root y admin)-->
          <div
            class="panel-confs__gestion-usuarios"
            v-if="this.usuarioLoggeado.perfil === 'root' || this.usuarioLoggeado.perfil === 'admin'"
          >
            <div class="panel-confs__cabecera" @click="mostrarContenido($refs.divConfs2)">
              <h4>Gestión de usuarios</h4>
            </div>
            <div class="panel-confs__contenido" ref="divConfs2">
              <!---->
              <!--Editar usuario existente-->
              <form
                id="form-edicion-usuarios"
                class="form"
                autocomplete="off"
                @submit.prevent="actualizarDatos('usuario')"
              >
                <h4 class="subapartado">Ver / editar / eliminar un usuario</h4>
                <!--seleccionar usuario-->
                <p class="field half">
                  <label
                    class="label"
                    for="select-usuarios"
                  >Búsqueda usuario (nombre o cód. empleado)</label>
                  <input
                    class="text-input"
                    id="select-usuarios"
                    name="select-usuarios"
                    type="search"
                    v-model="usuarioBq"
                  />
                </p>

                <!--boton eliminar usuario-->
                <p class="field half">
                  <button
                    id="btn-confs-delete"
                    class="button btn-confs-delete"
                    @click.prevent="eliminarUsuario(id_usuarioUS)"
                  >
                    <i class="fas fa-user-times"></i>&nbsp;&nbsp;Eliminar usuario
                  </button>
                </p>

                <!--seleccionar usuario-->
                <p class="field half" v-if="usuarioBq != '' && filtrarUsuarios.length > 0">
                  <label class="label" for="select-usuarios">Selecciona el usuario</label>
                  <select
                    class="select"
                    id="select-usuario"
                    v-model="id_usuarioUS"
                    @change="datosUsuarioSelec"
                  >
                    <option
                      v-for="usuario in filtrarUsuarios"
                      :key="usuario.id"
                      :value="usuario.id"
                    >({{usuario.cod_empleado}}) {{ usuario.nombre }}</option>
                  </select>
                </p>

                <p class="field half" v-if="usuarioBq != '' && filtrarUsuarios.length > 0"></p>

                <!--tipo perfil del usuario seleccionado-->
                <p class="field half">
                  <label class="label" for="perfil_usuario">Tipo perfil del usuario</label>
                  <select class="select" id="select-perfil_selec" v-model="perfilUS">
                    <option value="user">Usuario Común</option>
                    <option value="resp">Usuario Responsable</option>
                    <option value="admin">Usuario Administrador</option>
                  </select>
                </p>

                <!--responsable-->
                <p class="field half" v-if="this.id_usuarioUS != ''">
                  <label class="label" for="resp_del_usuario">Responsable del usuario</label>
                  <select class="select" id="select-perfil_selec" v-model="respdUS">
                    <option
                      v-for="responsable in responsables"
                      :key="responsable.id"
                      :value="responsable.id"
                    >
                      {{
                      responsable.nombre
                      }}
                    </option>
                  </select>
                </p>

                <!--nombre-->
                <p class="field required">
                  <label class="label" for="nombre_usuario">Nombre completo usuario</label>
                  <input
                    class="text-input"
                    id="nombre_usuario_selec"
                    name="nombre_usuario_selec"
                    type="text"
                    v-model="nombreUS"
                  />
                </p>

                <!--email-->
                <p class="field required">
                  <label class="label" for="email_usuario">Email usuario</label>
                  <input
                    class="text-input"
                    id="email_usuario_selec"
                    name="email_usuario_selec"
                    type="text"
                    v-model="emailUS"
                  />
                </p>

                <!--base clientes-->
                <p class="field half required">
                  <label class="label" for="select-base-1-us">Base clientes 1</label>
                  <select class="select" id="select-base-1-us" v-model="base_cli_1_US">
                    <option value>Todas</option>
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-2-us">Base clientes 2</label>
                  <select class="select" id="select-base-2-us" v-model="base_cli_2_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-3-us">Base clientes 3</label>
                  <select class="select" id="select-base-3-us" v-model="base_cli_3_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-4-us">Base clientes 4</label>
                  <select class="select" id="select-base-4-us" v-model="base_cli_4_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-5-us">Base clientes 5</label>
                  <select class="select" id="select-base-5-us" v-model="base_cli_5_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-6-us">Base clientes 6</label>
                  <select class="select" id="select-base-6-us" v-model="base_cli_6_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-7-us">Base clientes 7</label>
                  <select class="select" id="select-base-7-us" v-model="base_cli_7_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-8-us">Base clientes 8</label>
                  <select class="select" id="select-base-8-us" v-model="base_cli_8_US">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>

                <!--usuario-->
                <p class="field half required">
                  <label class="label" for="usuario_usuario">Usuario</label>
                  <input
                    class="text-input"
                    id="login_usuario_selec"
                    name="login_usuario_selec"
                    type="text"
                    v-model="login_usuarioUS"
                  />
                </p>

                <!--pass-->
                <p class="field half required">
                  <label class="label" for="pass_usuario">Contraseña usuario</label>
                  <input
                    class="text-input"
                    id="pass_usuario_selec"
                    name="pass_usuario_selec"
                    type="text"
                    v-model="pass_usuarioUS"
                  />
                </p>
                <!-- <i class="fas fa-eye eye" @click="cambiarTipoInput" v-show="this.esTipoPassword"></i>
                <i
                  class="fas fa-eye-slash eye"
                  @click="cambiarTipoInput"
                  v-show="!this.esTipoPassword"
                ></i>-->

                <button id="btn-confs-enviar" class="button" @click.prevent="enviarEmail()">
                  <i class="fas fa-paper-plane"></i>&nbsp;&nbsp;Enviar datos usuario
                </button>
                <button id="btn-confs-guardar" class="button" type="submit">
                  <i class="far fa-save"></i>&nbsp;&nbsp;Guardar datos usuario
                </button>
              </form>

              <!---->
              <!--Crear usuario-->
              <form
                id="form-crear-usuarios"
                class="form"
                autocomplete="off"
                @submit.prevent="axiosCrearUsuario"
              >
                <h4 class="subapartado">Crear un usuario nuevo</h4>
                <!--perfil-->
                <p class="field half required">
                  <label class="label" for="perfil_usuario">Tipo perfil del usuario</label>
                  <select class="select" id="select-perfil" v-model="perfilNU">
                    <option value="user" selected>Usuario Común</option>
                    <option value="resp">Usuario Responsable</option>
                    <option value="admin">Usuario Administrador</option>
                  </select>
                </p>

                <p class="field half"></p>

                <!--nombre-->
                <p class="field required">
                  <label class="label" for="nombre_nu">Nombre completo</label>
                  <input
                    class="text-input"
                    id="nombre_nu"
                    name="nombre_nu"
                    type="text"
                    v-model="nombreNU"
                  />
                </p>

                <!--email-->
                <p class="field required">
                  <label class="label" for="email_nu">Email</label>
                  <input
                    class="text-input"
                    id="email_nu"
                    name="email_nu"
                    type="text"
                    v-model="emailNU"
                  />
                </p>

                <!--cod. empleado-->
                <p class="field half required">
                  <label class="label" for="cod_empleado">Código de empleado</label>
                  <input
                    class="text-input"
                    id="cod_empleado"
                    name="cod_empleado"
                    type="text"
                    placeholder="ej: 1234"
                    v-model="cod_empleadoNU"
                  />
                </p>

                <!--empresa-->
                <p class="field half required">
                  <label class="label" for="select-empresa">Empresa</label>
                  <select class="select" id="select-empresa" v-model="empresaNU">
                    <option
                      v-for="empresa in empresas"
                      :key="empresa.id"
                      :value="empresa.id"
                    >({{ empresa.id }}) {{ empresa.nombre }}</option>
                  </select>
                </p>

                <!--departamento-->
                <p class="field half required">
                  <label class="label" for="select-dpto">Departamento</label>
                  <select class="select" id="select-dpto" v-model="departamentoNU">
                    <option
                      v-for="departamento in departamentos"
                      :key="departamento.id"
                      :value="departamento.id"
                    >
                      {{
                      departamento.nombre
                      }}
                    </option>
                  </select>
                </p>

                <!--responsables-->
                <p class="field half required">
                  <label class="label" for="select-resp">Responsable</label>
                  <select class="select" id="select-resp" v-model="responsableNU">
                    <option
                      v-for="responsable in responsables"
                      :key="responsable.id"
                      :value="responsable.id"
                    >
                      {{
                      responsable.nombre
                      }}
                    </option>
                  </select>
                </p>

                <!--base clientes-->
                <p class="field half required">
                  <label class="label" for="select-base-1">Base clientes 1</label>
                  <select class="select" id="select-base-1" v-model="base_cli_1">
                    <option value>Todas</option>
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-2">Base clientes 2</label>
                  <select class="select" id="select-base-2" v-model="base_cli_2">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-3">Base clientes 3</label>
                  <select class="select" id="select-base-3" v-model="base_cli_3">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-4">Base clientes 4</label>
                  <select class="select" id="select-base-4" v-model="base_cli_4">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-5">Base clientes 5</label>
                  <select class="select" id="select-base-5" v-model="base_cli_5">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-6">Base clientes 6</label>
                  <select class="select" id="select-base-6" v-model="base_cli_6">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-7">Base clientes 7</label>
                  <select class="select" id="select-base-7" v-model="base_cli_7">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>
                <p class="field half">
                  <label class="label" for="select-base-8">Base clientes 8</label>
                  <select class="select" id="select-base-8" v-model="base_cli_8">
                    <option
                      v-for="base in baseClientes"
                      :key="base.id"
                      :value="base.id"
                    >{{ base.nombre }}</option>
                  </select>
                </p>

                <!--usuario-->
                <p class="field half required">
                  <label class="label" for="usuario_nu">Usuario</label>
                  <input
                    class="text-input"
                    id="login_nu"
                    name="login_nu"
                    type="text"
                    v-model="login_usuarioNU"
                  />
                </p>

                <!--pass-->
                <p class="field half required">
                  <label class="label" for="pass_usuario">Contraseña</label>
                  <input
                    class="text-input"
                    id="pass_nu"
                    name="pass_nu"
                    type="text"
                    v-model="pass_usuarioNU"
                  />
                </p>
                <!-- <i class="fas fa-eye eye" @click="cambiarTipoInput" v-show="this.esTipoPassword"></i>
                <i
                  class="fas fa-eye-slash eye"
                  @click="cambiarTipoInput"
                  v-show="!this.esTipoPassword"
                ></i>-->

                <button id="btn-confs-crear" class="button" type="submit">
                  <i class="fas fa-user-plus"></i>&nbsp;&nbsp;Crear nuevo usuario
                </button>
              </form>
            </div>
          </div>
          <!--...fin Gestion Usuarios(Perfiles root y admin)-->

          <!---->
          <!---->
          <!---->
          <!--Gestión Aplicación-->
          <div
            class="panel-confs__gestion_app"
            v-if="this.usuarioLoggeado.perfil === 'root' || this.usuarioLoggeado.perfil === 'admin'"
          >
            <div class="panel-confs__cabecera" @click="mostrarContenido($refs.divConfs3)">
              <h4>Gestión de la Aplicación</h4>
            </div>
            <div class="panel-confs__contenido" ref="divConfs3">
              <!---->
              <!--Editar tipo contacto existente-->
              <form
                id="form-edicion-tipo-contacto"
                class="form"
                autocomplete="off"
                @submit.prevent="actualizarDatosApp('tipo_contacto', id_tipoContactoSE, editarTipoContacto, anadirTipoContacto)"
              >
                <h4 class="subapartado">Editar tipos de contacto</h4>

                <!---->
                <!--tipos de contacto-->
                <p class="field">
                  <label class="label" for="tipos-contacto">Seleccionar tipo de contacto existente</label>
                  <select class="select" id="tipos-contacto" v-model="id_tipoContactoSE">
                    <option
                      v-for="contacto in tipoContacto"
                      :key="contacto.id"
                      :value="contacto.id"
                    >{{ contacto.tContactoR }}</option>
                  </select>
                </p>

                <!--editar tipo de contacto-->
                <p class="field half" v-if="id_tipoContactoSE !== ''">
                  <label class="label" for="edi-tipo-contacto">Editar tipo de contacto</label>
                  <input
                    class="text-input"
                    id="edi-tipo-contacto"
                    name="edi-tipo-contacto"
                    type="text"
                    v-model="editarTipoContacto"
                  />
                </p>

                <!--boton eliminar tipo contacto-->
                <p class="field half" v-if="id_tipoContactoSE !== ''">
                  <button
                    id="btn-confs-delete-tipo-contacto"
                    class="button btn-confs-delete"
                    @click.prevent="eliminarTipoContacto(id_tipoContactoSE)"
                  >
                    <i class="fas fa-user-times"></i>&nbsp;&nbsp;Eliminar tipo contacto
                  </button>
                </p>

                <!--añadir tipo de contacto-->
                <p class="field">
                  <label class="label" for="anadir-tipo-contacto">Añadir un nuevo tipo de contacto</label>
                  <input
                    class="text-input"
                    id="anadir-tipo-contacto"
                    name="anadir-tipo-contacto"
                    type="text"
                    v-model="anadirTipoContacto"
                  />
                </p>

                <button id="btn-confs-guardar-app-tipo-contacto" class="button" type="submit">
                  <i class="far fa-save"></i>&nbsp;&nbsp;Guardar cambios
                </button>
              </form>

              <!---->
              <!--Editar lugar existente-->
              <form
                id="form-edicion-lugar"
                class="form"
                autocomplete="off"
                @submit.prevent="actualizarDatosApp('lugares', id_lugarSE, editarLugar, anadirLugar)"
              >
                <h4 class="subapartado">Editar Lugares</h4>

                <!---->
                <!--lugares-->
                <p class="field">
                  <label class="label" for="lugares">Seleccionar lugar existente</label>
                  <select class="select" id="lugares" v-model="id_lugarSE">
                    <option
                      v-for="lugar in lugares"
                      :key="lugar.id"
                      :value="lugar.id"
                    >{{ lugar.nombre }}</option>
                  </select>
                </p>

                <!--editar lugar-->
                <p class="field half" v-if="id_lugarSE !== ''">
                  <label class="label" for="edi-lugar">Editar lugar</label>
                  <input
                    class="text-input"
                    id="edi-lugar"
                    name="edi-lugar"
                    type="text"
                    v-model="editarLugar"
                  />
                </p>

                <!--boton eliminar lugar-->
                <p class="field half" v-if="id_lugarSE !== ''">
                  <button
                    id="btn-confs-delete-lugar"
                    class="button btn-confs-delete"
                    @click.prevent="eliminarLugar(id_lugarSE)"
                  >
                    <i class="fas fa-user-times"></i>&nbsp;&nbsp;Eliminar lugar
                  </button>
                </p>

                <!--añadir lugar-->
                <p class="field">
                  <label class="label" for="anadir-lugar">Añadir un nuevo lugar</label>
                  <input
                    class="text-input"
                    id="anadir-lugar"
                    name="anadir-lugar"
                    type="text"
                    v-model="anadirLugar"
                  />
                </p>

                <button id="btn-confs-guardar-app-lugar" class="button" type="submit">
                  <i class="far fa-save"></i>&nbsp;&nbsp;Guardar cambios
                </button>
              </form>
            </div>
          </div>
          <!--...fin Gestion de la Aplicación(Perfiles root y admin)-->

          <!---->
          <!---->
          <!---->
          <!--Gestion de Clientes (perfiles root y admin)-->
          <!--<div
            class="panel-confs__gestion-empresa"
            v-if="this.usuarioLoggeado.perfil === 'root' || this.usuarioLoggeado.perfil === 'admin'"
          >
            <div class="panel-confs__cabecera" @click="mostrarContenido($refs.divConfs3)">
              <h4>Gestión de empresa</h4>
            </div>
            <div class="panel-confs__contenido" ref="divConfs3">
              <form id="form-editar-empresa" class="form" autocomplete="off" @submit.prevent>
                <h4 class="subapartado">Editar datos empresa</h4>

                <p class="field half required">
                  <label class="label" for="perfil_usuario">Editar apartado</label>
                  <select class="select" id="select-perfil" v-model="tipo_gestion_empresa">
                    <option value="user" selected>Cliente</option>
                    <option value="resp">Departamento</option>
                  </select>
                </p>

                <p class="field half"></p>

                <button id="btn-confs-crear" class="button" type="submit">
                  <i class="far fa-save"></i>&nbsp;&nbsp;Guardar
                </button>
              </form>
            </div>
          </div>-->
          <!--...fin Gestion Clientes(Perfiles root y admin)-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import $ from "jquery";

export default {
  name: "Confs",
  data() {
    return {
      // usuario loggeado
      id_usuario: this.$store.state.usuarioLoggeado.id_usuario,
      nombre: this.$store.state.usuarioLoggeado.nombre,
      email: this.$store.state.usuarioLoggeado.email,
      login_usuario: this.$store.state.usuarioLoggeado.login_usuario,
      pass_usuario: this.$store.state.usuarioLoggeado.pass_usuario,
      // usuario seleccionado en gestión de usuarios
      id_usuarioUS: "",
      perfilUS: "",
      respdUS: "",
      nombreUS: "",
      emailUS: "",
      login_usuarioUS: "",
      pass_usuarioUS: "",
      usuarioBq: "",
      usuarioTgt: null,
      base_cli_1_US: "",
      base_cli_2_US: "",
      base_cli_3_US: "",
      base_cli_4_US: "",
      base_cli_5_US: "",
      base_cli_6_US: "",
      base_cli_7_US: "",
      base_cli_8_US: "",
      // nuevo usuario
      perfilNU: "",
      empresaNU: "",
      cod_empleadoNU: "",
      nombreNU: "",
      departamentoNU: "",
      responsableNU: "",
      emailNU: "",
      login_usuarioNU: "",
      pass_usuarioNU: "",
      fecha_altaNU: "",
      base_cli_1: "",
      base_cli_2: "",
      base_cli_3: "",
      base_cli_4: "",
      base_cli_5: "",
      base_cli_6: "",
      base_cli_7: "",
      base_cli_8: "",
      // gestión empresa
      tipo_gestion_empresa: "",
      // gestión Aplicación
      id_tipoContactoSE: "",
      editarTipoContacto: "",
      anadirTipoContacto: "",
      id_lugarSE: "",
      editarLugar: "",
      anadirLugar: ""
    };
  },
  created() {
    this.estaLoggeado();
    this.axiosUsuarios();
    this.axiosResponsables();
    this.axiosDepartamentos();
    this.axiosEmpresas();
    this.axiosBaseClientes();
    this.axiosTipoContacto();
    this.axiosLugares();
    this.$store.commit("cambiarTipoInput", "password");
  },
  computed: {
    ...mapState([
      "confs",
      "usuarioLoggeado",
      "esTipoPassword",
      "tipoInput",
      "usuarios",
      "departamentos",
      "responsables",
      "empresas",
      "lugares",
      "tipoContacto",
      "baseClientes"
    ]),
    filtrarUsuarios() {
      let resp;
      if (this.usuarioBq.length > 0) {
        let usus = this.usuarios;
        resp = Object.keys(usus)
          .map(key => usus[key])
          .filter(
            u =>
              u.nombre.toLowerCase().includes(this.usuarioBq) ||
              u.cod_empleado.includes(this.usuarioBq)
          );
      }
      return resp;
    }
  },
  methods: {
    // si el usuario no está loggeado lo redirecciona a '/'
    estaLoggeado() {
      if (!this.$store.state.loginSuccess) {
        this.$store.dispatch("redirectLogin");
      }
    },
    // carga usuarios
    axiosUsuarios() {
      this.$store.dispatch("axiosUsuarios");
    },
    // carga departamentos
    axiosDepartamentos() {
      this.$store.dispatch("axiosDepartamentos");
    },
    // carga responsables
    axiosResponsables() {
      this.$store.dispatch("axiosResponsables");
    },
    // carga empresas
    axiosEmpresas() {
      this.$store.dispatch("axiosEmpresas");
    },
    // carga lugares
    axiosLugares() {
      this.$store.dispatch("axiosLugares");
    },
    // carga base clientes
    axiosBaseClientes() {
      this.$store.dispatch("axiosBaseClientes");
    },
    // carga tipos contacto
    axiosTipoContacto() {
      this.$store.dispatch("axiosTipoContacto");
    },
    // desplegado mostrar
    mostrarContenido(n) {
      this.$store.commit("cambiarTipoPasswordBoolean", true);
      this.$store.commit("cambiarTipoInput", "password");
      $(n).slideToggle(300);
    },
    // desplegado ocultar
    cerrarContenido(n) {
      $(n).slideUp(300);
    },
    // carga de los datos del usuario seleccionado
    datosUsuarioSelec() {
      let users = this.usuarios;
      let resp = Object.keys(users)
        .map(key => users[key])
        .filter(user => user.id === this.id_usuarioUS);

      this.id_usuarioUS = resp[0].id;
      this.perfilUS = resp[0].perfil;
      this.respdUS = resp[0].responsable;
      this.nombreUS = resp[0].nombre;
      this.emailUS = resp[0].email;
      this.login_usuarioUS = resp[0].login_usuario;
      this.pass_usuarioUS = resp[0].pass_usuario;
      this.base_cli_1_US =
        resp[0].bases[0].id_base_clientes === undefined
          ? ""
          : resp[0].bases[0].id_base_clientes;
      this.base_cli_2_US =
        resp[0].bases[1].id_base_clientes === undefined
          ? ""
          : resp[0].bases[1].id_base_clientes;
      this.base_cli_3_US =
        resp[0].bases[2].id_base_clientes === undefined
          ? ""
          : resp[0].bases[2].id_base_clientes;
      this.base_cli_4_US =
        resp[0].bases[3].id_base_clientes === undefined
          ? ""
          : resp[0].bases[3].id_base_clientes;
      this.base_cli_5_US =
        resp[0].bases[4].id_base_clientes === undefined
          ? ""
          : resp[0].bases[4].id_base_clientes;
      this.base_cli_6_US =
        resp[0].bases[5].id_base_clientes === undefined
          ? ""
          : resp[0].bases[5].id_base_clientes;
      this.base_cli_7_US =
        resp[0].bases[6].id_base_clientes === undefined
          ? ""
          : resp[0].bases[6].id_base_clientes;
      this.base_cli_8_US =
        resp[0].bases[7].id_base_clientes === undefined
          ? ""
          : resp[0].bases[7].id_base_clientes;
    },
    actualizarDatosApp(tgt, id, dato, ndato) {
      let payload;
      if (tgt === "lugares") {
        payload = {
          id_lugar: id,
          editar_datos: dato,
          nuevos_datos: ndato
        };
      } else if (tgt === "tipo_contacto") {
        payload = {
          id_tipo_contacto: id,
          editar_datos: dato,
          nuevos_datos: ndato
        };
      }
      axios
        .post(`../api/api-toi/update_${tgt}.php`, payload)
        .then(() => {
          this.$swal({
            title: `Datos guardados correctamente`,
            type: "success",
            showConfirmButton: false,
            timer: 1800
          });
          this.id_tipoContactoSE = "";
          this.editarTipoContacto = "";
          this.anadirTipoContacto = "";
          this.id_lugarSE = "";
          this.editarLugar = "";
          this.anadirLugar = "";
          this.axiosTipoContacto();
          this.axiosLugares();
        })
        .catch(err => {
          this.$swal({
            title: "Error al conectar a la base de datos",
            type: "error",
            text: err,
            showConfirmButton: true
            /* timer: 1600 */
          });
        });
    },
    // actualizar datos del usuario loggeado o del usuario selecccionado
    actualizarDatos(t) {
      let payload;
      if (t === "misdatos") {
        payload = {
          id_usuario: this.id_usuario,
          nombre: this.nombre,
          email: this.email,
          login_usuario: this.login_usuario,
          pass_usuario: this.pass_usuario
        };
      } else if (t === "usuario") {
        payload = {
          id_usuario: this.id_usuarioUS,
          perfil: this.perfilUS,
          id_responsable: this.respdUS,
          nombre: this.nombreUS,
          email: this.emailUS,
          base_cli_1_US: this.base_cli_1_US,
          base_cli_2_US: this.base_cli_2_US,
          base_cli_3_US: this.base_cli_3_US,
          base_cli_4_US: this.base_cli_4_US,
          base_cli_5_US: this.base_cli_5_US,
          base_cli_6_US: this.base_cli_6_US,
          base_cli_7_US: this.base_cli_7_US,
          base_cli_8_US: this.base_cli_8_US,
          login_usuario: this.login_usuarioUS,
          pass_usuario: this.pass_usuarioUS
        };
      }
      axios
        .post(`../api/api-toi/update_usuarios.php`, payload)
        .then(() => {
          this.$swal({
            title: `Datos guardados correctamente`,
            type: "success",
            showConfirmButton: false,
            timer: 1800
          });
          if (t === "misdatos") {
            this.id_usuario = "";
            this.nombre = "";
            this.email = "";
            this.login_usuario = "";
            this.pass_usuario = "";
          } else if (t === "usuario") {
            this.id_usuarioUS = "";
            this.perfilUS = "";
            this.usuarioBq = "";
            this.usuarioTgt = null;
            this.respdUS = "";
            this.nombreUS = "";
            this.emailUS = "";
            this.base_cli_1_US = "";
            this.base_cli_2_US = "";
            this.base_cli_3_US = "";
            this.base_cli_4_US = "";
            this.base_cli_5_US = "";
            this.base_cli_6_US = "";
            this.base_cli_7_US = "";
            this.base_cli_8_US = "";
            this.login_usuarioUS = "";
            this.pass_usuarioUS = "";
          }
          this.axiosUsuarios();
        })
        .catch(err => {
          this.$swal({
            title: "Error al conectar a la base de datos",
            type: "error",
            text: err,
            showConfirmButton: true
            /* timer: 1600 */
          });
        });
    },
    // Crear nuevo usuario
    axiosCrearUsuario() {
      let payload = {
        perfil: this.perfilNU,
        id_empresa: this.empresaNU,
        cod_empleado: this.cod_empleadoNU,
        nombre: this.nombreNU,
        id_departamento: this.departamentoNU,
        id_responsable: this.responsableNU,
        email: this.emailNU,
        login_usuario: this.login_usuarioNU,
        pass_usuario: this.pass_usuarioNU,
        base_cli_1: this.base_cli_1,
        base_cli_2: this.base_cli_2,
        base_cli_3: this.base_cli_3,
        base_cli_4: this.base_cli_4,
        base_cli_5: this.base_cli_5,
        base_cli_6: this.base_cli_6,
        base_cli_7: this.base_cli_7,
        base_cli_8: this.base_cli_8
      };
      axios
        .post(`../api/api-toi/insert_usuarios.php`, payload)
        .then(() => {
          this.$swal({
            title: `Usuario creado correctamente`,
            type: "success",
            showConfirmButton: false,
            timer: 1800
          });
          this.axiosUsuarios();
          this.perfilNU = "";
          this.empresaNU = "";
          this.cod_empleadoNU = "";
          this.nombreNU = "";
          this.departamentoNU = "";
          this.responsableNU = "";
          this.emailNU = "";
          this.login_usuarioNU = "";
          this.pass_usuarioNU = "";
          this.base_cli_1 = "";
          this.base_cli_2 = "";
          this.base_cli_3 = "";
          this.base_cli_4 = "";
          this.base_cli_5 = "";
          this.base_cli_6 = "";
          this.base_cli_7 = "";
          this.base_cli_8 = "";
        })
        .catch(err => {
          this.$swal({
            title: "Error al crear el usuario",
            text: err,
            type: "error",
            showConfirmButton: true
            /* timer: 1600 */
          });
        });
    },
    // Cambiar tipo input del password
    cambiarTipoInput() {
      this.$store.commit("cambiarTipoPassword");
      if (!this.esTipoPassword) {
        this.$store.commit("cambiarTipoInput", "text");
      } else {
        this.$store.commit("cambiarTipoInput", "password");
      }
    },
    // Enviar email a usuario
    enviarEmail() {
      let payload = {
        nombre: this.nombreUS,
        email: this.emailUS,
        login: this.login_usuarioUS,
        pass: this.pass_usuarioUS
      };
      axios
        .post(`../api/api-toi/enviar-datos-usuario-email.php`, payload)
        .then(resp => {
          console.log(resp.data);
          this.$swal({
            title: "Datos enviados al usuario",
            type: "success",
            showConfirmButton: false,
            timer: 1800
          });
          this.axiosUsuarios();
          this.nombreUS = "";
          this.emailUS = "";
          this.login_usuarioUS = "";
          this.pass_usuarioUS = "";
        })
        .catch(err => {
          this.$swal({
            title: "Error al enviar los datos al usuario",
            type: "error",
            text: err,
            showConfirmButton: true,
            confirmButtonText: "Cerrar"
            /* timer: 1800 */
          });
        });
    },
    // Eliminar tipo contacto
    eliminarTipoContacto(id) {
      this.$swal({
        title: "¿Deseas eliminar el tipo de contacto?",
        type: "warning",
        showCancelButton: true,
        showCloseButton: false,
        showLoaderOnConfirm: false,
        allowEnterKey: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          axios
            .post(`../api/api-toi/delete_tipo_contacto.php`, {
              id_tipo_contacto: id
            })
            .then(() => {
              this.$swal({
                title: `Tipo contacto eliminado correctamente`,
                type: "success",
                showConfirmButton: false,
                timer: 1800
              });
              this.axiosTipoContacto();
            })
            .catch(err => {
              this.$swal({
                title: "Error al eliminar el tipo de contacto",
                type: "error",
                text: err,
                showConfirmButton: true,
                confirmButtonText: "Cerrar"
                /* timer: 1800 */
              });
            });
          this.editarTipoContacto = "";
        }
      });
    },
    // Eliminar lugar
    eliminarLugar(id) {
      this.$swal({
        title: "¿Deseas eliminar el lugar?",
        type: "warning",
        showCancelButton: true,
        showCloseButton: false,
        showLoaderOnConfirm: false,
        allowEnterKey: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          axios
            .post(`../api/api-toi/delete_lugares.php`, {
              id_lugar: id
            })
            .then(() => {
              this.$swal({
                title: `Lugar eliminado correctamente`,
                type: "success",
                showConfirmButton: false,
                timer: 1800
              });
              this.axiosLugares();
            })
            .catch(err => {
              this.$swal({
                title: "Error al eliminar el lugar",
                type: "error",
                text: err,
                showConfirmButton: true,
                confirmButtonText: "Cerrar"
                /* timer: 1800 */
              });
            });
          this.editarLugar = "";
        }
      });
    },
    // Eliminar usuario
    eliminarUsuario(id) {
      this.$swal({
        title: "¿Deseas eliminar el usuario?",
        text: "Una vez eliminado no podrá ser recuperado",
        type: "warning",
        showCancelButton: true,
        showCloseButton: false,
        showLoaderOnConfirm: false,
        allowEnterKey: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          axios
            .post(`../api/api-toi/delete_usuarios.php`, {
              id_usuario: id
            })
            .then(() => {
              this.$swal({
                title: `Usuario eliminado correctamente`,
                type: "success",
                showConfirmButton: false,
                timer: 1800
              });
              this.axiosUsuarios();
            })
            .catch(err => {
              this.$swal({
                title: "Error al eliminar el usuario",
                type: "error",
                text: err,
                showConfirmButton: true,
                confirmButtonText: "Cerrar"
                /* timer: 1800 */
              });
            });
        }
      });
    }
  },
  watch: {
    usuarioBq: function(value) {
      if (value === "") {
        this.usuarioTgt = null;
        this.nombreUS = "";
        this.perfilUS = "";
        this.respdUS = "";
        this.emailUS = "";
        this.base_cli_1_US = "";
        this.base_cli_2_US = "";
        this.base_cli_3_US = "";
        this.base_cli_4_US = "";
        this.base_cli_5_US = "";
        this.base_cli_6_US = "";
        this.base_cli_7_US = "";
        this.base_cli_8_US = "";
        this.login_usuarioUS = "";
        this.pass_usuarioUS = "";
      }
    },
    base_cli_1_US: function(value) {
      if (value === "") {
        this.base_cli_2_US = "";
        this.base_cli_3_US = "";
        this.base_cli_4_US = "";
        this.base_cli_5_US = "";
        this.base_cli_6_US = "";
        this.base_cli_7_US = "";
        this.base_cli_8_US = "";
      }
    },
    id_tipoContactoSE: function(value) {
      if (value !== "") {
        let tpc = this.tipoContacto;
        this.editarTipoContacto = Object.keys(tpc)
          .map(key => tpc[key])
          .filter(c => c.id === this.id_tipoContactoSE)[0].tContactoR;
      }
    },
    id_lugarSE: function(value) {
      if (value !== "") {
        let lgr = this.lugares;
        this.editarLugar = Object.keys(lgr)
          .map(key => lgr[key])
          .filter(l => l.id === this.id_lugarSE)[0].nombre;
      }
    }
  }
};
</script>

<style scoped>
#div-configuraciones {
  color: rgba(17, 47, 145, 1);
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}

.panel-confs {
  grid-column: 1/1;
  grid-row: 1/1;
}

.panel-confs__cabecera {
  padding: 0.7em;
  font-size: 0.9em;
  border-top: 1px solid aliceblue;
  background: rgb(17, 189, 186);
  color: rgba(17, 47, 145, 1);
  cursor: pointer;
}

.panel-confs:last-child > .panel-confs__cabecera {
  border-bottom: 1px solid aliceblue;
}

.panel-confs__contenido {
  padding: 24px;
  padding: 1.5rem;
  display: none;
  background: rgba(17, 189, 186, 0.3);
  overflow: hidden;
}

.panel-confs__ajustes ul {
  list-style-type: none;
  padding: 1em 2em;
}

/* mostrar contraseña */
.eye {
  margin: 0;
  padding: 11.2px;
  padding: 0.7rem;
  overflow: hidden;
  position: relative;
  top: -70px;
  left: 94%;
  cursor: pointer;
}

/* Botones */
button#btn-confs-guardar {
  margin-left: 0.5em;
}

button.btn-confs-delete {
  margin-left: 0.5em;
  background-color: rgb(216, 19, 29);
  color: rgb(225, 227, 235);
  border-color: rgb(216, 19, 29);
}

button#btn-confs-delete:hover {
  color: rgb(216, 19, 29);
  background-color: rgb(225, 227, 235);
}

h4.subapartado {
  width: 100%;
  text-align: left;
  color: rgba(17, 47, 145, 1);

  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.7),
    transparent
  );
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0.7),
    transparent
  );
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7), transparent);

  border-top: 2px solid rgba(17, 47, 145, 1);
  padding: 0.8em 0 0.8em 2em;
  margin: 2em 0 1.15em 0;
}

.select {
  background-color: rgba(17, 47, 145, 0.35);
}
</style>
