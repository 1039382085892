<!--eslint-disable-->
<template>
  <footer>
    <div id="footer-div">
      <p>{{ anioActual }} &copy; Grupo Pastores</p>
      <p>
        Powered by
        <strong>URDI NUEVAS TECNOLOGIAS S.L.</strong> &bull; All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
  computed: {
    anioActual() {
      return new Date().getFullYear().toString();
    }
  }
};
</script>

<style scoped>
footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Rubik";
  color: rgba(17, 47, 145, 1);
  letter-spacing: 0.03em;
  font-size: 0.9em;
  font-weight: 200;
  line-height: 1.25em;
}

#footer-div {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding: 1.75rem;
  z-index: 800;

  background: rgba(242, 246, 248, 1);
  background: -moz-linear-gradient(
    top,
    rgba(242, 246, 248, 1) 0%,
    rgba(216, 225, 231, 1) 50%,
    rgba(181, 198, 208, 1) 51%,
    rgba(224, 239, 249, 1) 100%
  );
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(242, 246, 248, 1)),
    color-stop(50%, rgba(216, 225, 231, 1)),
    color-stop(51%, rgba(181, 198, 208, 1)),
    color-stop(100%, rgba(224, 239, 249, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(242, 246, 248, 1) 0%,
    rgba(216, 225, 231, 1) 50%,
    rgba(181, 198, 208, 1) 51%,
    rgba(224, 239, 249, 1) 100%
  );
  background: -o-linear-gradient(
    top,
    rgba(242, 246, 248, 1) 0%,
    rgba(216, 225, 231, 1) 50%,
    rgba(181, 198, 208, 1) 51%,
    rgba(224, 239, 249, 1) 100%
  );
  background: -ms-linear-gradient(
    top,
    rgba(242, 246, 248, 1) 0%,
    rgba(216, 225, 231, 1) 50%,
    rgba(181, 198, 208, 1) 51%,
    rgba(224, 239, 249, 1) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(242, 246, 248, 1) 0%,
    rgba(216, 225, 231, 1) 50%,
    rgba(181, 198, 208, 1) 51%,
    rgba(224, 239, 249, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f2f6f8', endColorstr='#e0eff9', GradientType=0 );
}
</style>
