import Vue from "vue";
import Router from "vue-router";
import Login from "./views/Login.vue";
import NuevaReunion from "./views/Nueva-reunion.vue";
import Reuniones from "./views/Reuniones.vue";
import Graficas from "./views/Graficas.vue";
import Confs from "./views/Confs.vue";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      name: "Login",
      component: Login
    },
    {
      path: "/nueva-reunion",
      name: "Nueva-reunion",
      component: NuevaReunion
    },
    {
      path: "/reuniones",
      name: "Reuniones",
      component: Reuniones
    },
    {
      path: "/graficas",
      name: "Graficas",
      component: Graficas
    },
    {
      path: "/confs",
      name: "Confs",
      component: Confs
    }
  ]
});
