<!--eslint-disable-->
<template>
  <section class="seccion-componente">
    <div class="nombre-seccion">Nueva reunión</div>

    <div class="contenido-seccion">
      <form
        id="form-nueva-reunion"
        class="form"
        autocomplete="off"
        @submit.prevent="axiosCrearReunion"
      >
        <!--nombre reunión-->
        <!-- <p class="field">
          <label class="label" for="nombre_reunion">Nombre de la reunión:</label>
          <input
            class="text-input"
            id="nombre_reunion"
            name="nombre_reunion"
            required
            type="text"
            v-model="nombreR"
          />
        </p>-->

        <!--fecha-->
        <p class="field half required">
          <label class="label" for="fecha_reunion">Fecha de la reunión:</label>
          <input
            class="text-input"
            id="fecha_reunion"
            name="fecha_reunion"
            required
            type="date"
            v-model="fechaR"
          />
        </p>

        <!--hora-->
        <p class="field half required">
          <label class="label" for="hora_reunion">Hora de la reunión:</label>
          <input
            class="text-input"
            id="hora_reunion"
            name="hora_reunion"
            required
            type="time"
            v-model="horaR"
          />
        </p>

        <!--quien la solicita-->
        <div class="field required">
          <label class="label">¿Quién solicita la visita?</label>
          <ul class="options">
            <li class="option">
              <input
                class="option-input"
                id="option-cliente"
                name="option-solicita"
                type="radio"
                value="Cliente"
                v-model="solicitaR"
              />
              <label class="option-label" for="option-cliente">Cliente</label>
            </li>
            <li class="option">
              <input
                class="option-input"
                id="option-propia"
                name="option-solicita"
                type="radio"
                value="Propia"
                v-model="solicitaR"
              />
              <label class="option-label" for="option-propia">Propia</label>
            </li>
          </ul>
        </div>

        <!--tipo de contacto-->
        <p class="field half">
          <label class="label" for="select-tipo-contacto">Tipo de contacto:</label>
          <select class="select" id="select-tipo-contacto" v-model="id_tipo_contacto">
            <option disabled selected>Seleccionar una opción...</option>
            <option
              v-for="tipo in tipoContacto"
              :key="tipo.id"
              :value="tipo.id"
            >{{ tipo.tContactoR }}</option>
          </select>
        </p>

        <!--lugar-->
        <p class="field half">
          <label class="label" for="select-lugar">Lugar:</label>
          <select class="select" id="select-lugar" v-model="id_lugar">
            <option disabled selected>Seleccionar una opción...</option>
            <option v-for="lugar in lugares" :key="lugar.id" :value="lugar.id">{{ lugar.nombre }}</option>
          </select>
        </p>

        <!--cliente-->
        <p class="field">
          <label class="label" for="cliente_reunion">Búsqueda de cliente</label>
          <v-select
            class="style-chooser"
            label="nombre"
            :options="ordenarClientes"
            v-model="clienteTgt"
          >
            <div slot="no-options">No existen clientes con ese criterio</div>
          </v-select>
        </p>

        <!--informe visita-->
        <p class="field">
          <label class="label" for="informe-visita">Informe Visita (máx. 200 caracteres)</label>
          <textarea
            class="textarea"
            cols="50"
            id="informe-visita"
            name="informe-visita"
            rows="4"
            maxlength="200"
            v-model="informeR"
          ></textarea>
        </p>

        <!--impresión visita-->
        <div class="field required">
          <label class="label">¿Qué impresión te ha causado la visita?</label>
          <ul class="options">
            <!--Mala-->
            <li class="option">
              <input
                class="option-input"
                id="option-M"
                name="option-puntuacion"
                type="radio"
                value="M"
                v-model="puntuacionR"
              />
              <label class="option-label emoticono-label" for="option-M">
                <i class="far fa-sad-cry emoticono"></i>
                <span>Mala</span>
              </label>
            </li>
            <!--Regular-->
            <li class="option">
              <input
                class="option-input"
                id="option-R"
                name="option-puntuacion"
                type="radio"
                value="R"
                v-model="puntuacionR"
              />
              <label class="option-label emoticono-label" for="option-R">
                <i class="far fa-frown-open emoticono"></i>
                <span>Regular</span>
              </label>
            </li>
            <!--Buena-->
            <li class="option">
              <input
                class="option-input"
                id="option-B"
                name="option-puntuacion"
                type="radio"
                value="B"
                v-model="puntuacionR"
              />
              <label class="option-label emoticono-label" for="option-B">
                <i class="far fa-grin emoticono"></i>
                <span>Buena</span>
              </label>
            </li>
            <!--Muy Buena-->
            <li class="option">
              <input
                class="option-input"
                id="option-MB"
                name="option-puntuacion"
                type="radio"
                value="MB"
                v-model="puntuacionR"
              />
              <label class="option-label emoticono-label" for="option-MB">
                <i class="far fa-grin-beam emoticono"></i>
                <span>Muy Buena</span>
              </label>
            </li>
            <!--Excelente-->
            <li class="option">
              <input
                class="option-input"
                id="option-E"
                name="option-puntuacion"
                type="radio"
                value="E"
                v-model="puntuacionR"
              />
              <label class="option-label emoticono-label" for="option-E">
                <i class="far fa-grin-stars emoticono"></i>
                <span>Excelente</span>
              </label>
            </li>
          </ul>
        </div>

        <p class="field half"></p>
        <p class="field half">
          <button class="button" type="submit">
            <i class="far fa-save"></i>&nbsp;&nbsp;Guardar reunión
          </button>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import _sortBy from "lodash.sortby";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import router from "@/router";
import { mapState } from "vuex";

export default {
  name: "NuevaReunion",
  data() {
    return {
      id: null,
      id_usuario: null,
      id_cliente: null,
      id_lugar: null,
      id_tipo_contacto: null,
      fechaR: new Date().toISOString().substr(0, 10),
      horaR: new Date()
        .toLocaleString()
        .split(" ")[1]
        .substr(0, 5),
      //nombreR: "",
      solicitaR: "",
      informeR: "",
      puntuacionR: "",
      clienteTgt: null
    };
  },
  components: { vSelect },
  created() {
    this.estaLoggeado();
    this.axiosClientes();
    this.axiosLugares();
    this.axiosTipoContacto();
  },
  computed: {
    ...mapState([
      "clientes",
      "lugares",
      "tipoContacto",
      "reuniones",
      "usuarioLoggeado"
    ]),
    ordenarClientes() {
      let arrClientes = this.clientes;
      return _sortBy(arrClientes, o => o.nombre);
    }
  },
  methods: {
    // si el usuario no está loggeado lo redirecciona a '/'
    estaLoggeado() {
      if (!this.$store.state.loginSuccess) {
        this.$store.dispatch("redirectLogin");
      }
    },
    // axios
    axiosLugares() {
      this.$store.dispatch("axiosLugares");
    },
    axiosClientes() {
      this.$store.dispatch("axiosClientes");
    },
    axiosTipoContacto() {
      this.$store.dispatch("axiosTipoContacto");
    },
    crearNombreReunion() {
      if (this.clienteTgt !== null) {
        let nomContacto = "";
        let nomLugar = "";
        let nomCliente = this.clienteTgt.nombre;
        let resp = "";
        nomContacto = Object.keys(this.tipoContacto)
          .map(key => this.tipoContacto[key])
          .filter(c => c.id === this.id_tipo_contacto)[0].tContactoR;
        nomLugar = Object.keys(this.lugares)
          .map(key => this.lugares[key])
          .filter(c => c.id === this.id_lugar)[0].nombre;
        resp = `${nomCliente}, ${nomContacto} (${nomLugar})`;
        return resp;
      } else {
        return null;
      }
    },
    axiosCrearReunion() {
      let payload = {
        id_usuario: this.usuarioLoggeado.id_usuario,
        id_cliente: this.id_cliente,
        id_lugar: this.id_lugar,
        id_tipo_contacto: this.id_tipo_contacto,
        fechaHora_reunion: `${this.fechaR} ${this.horaR}`,
        nombre: this.crearNombreReunion(),
        solicita: this.solicitaR,
        informe: this.informeR,
        puntuacion: this.puntuacionR
      };
      axios
        .post(`../api/api-toi/insert_reuniones.php`, payload)
        .then(() => {
          this.$swal({
            title: `Reunión creada correctamente`,
            type: "success",
            showConfirmButton: false,
            timer: 1800
          });
          // redirección a reuniones una vez creada la reunión
          router.push({ path: "/reuniones" });
        })
        .catch(err => {
          this.$swal({
            title: "Error al grabar la reunión",
            type: "error",
            text: err,
            showConfirmButton: true
            /* timer: 1600 */
          });
        });
    }
  },
  watch: {
    clienteTgt: function(value) {
      if (value !== null) {
        this.id_cliente = this.clienteTgt.id;
      }
    }
  }
};
</script>

<style>
.form .label,
.form .checkbox-input + label,
.form .option-input + label,
.form .text-input,
.form .textarea,
.form .select,
.form .message,
.form .button {
  padding: 0.7em 1em;
  /* -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; */
  outline: none;
  line-height: normal;
  border-radius: 0;
  border: none;
  background: none;
  display: block;
  cursor: pointer;
}

form {
  padding-top: 30px;
}

/* Etiquetas del formulario */
.form .label {
  font-family: Rubik;
  color: rgba(17, 47, 145, 1);
  padding: 3px 12px 4px 0;
  letter-spacing: 0.025em;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.25;
  position: relative;
  z-index: 100;
}

/* Campos requeridos */
.required .form .label:after,
.form .required .label:after {
  content: " *";
  color: #ec2f36;
  font-weight: bolder;
  font-size: 1.35em;
  vertical-align: top;
}

/* Inputs */
.form .checkbox-input + label,
.form .option-input + label,
.form .text-input,
.form .textarea,
.form .select {
  font: inherit;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  background: rgba(17, 47, 145, 0.18);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 1);
  color: rgba(17, 47, 145, 1);
  position: relative;
  transition: 0.4s all;
}

.form .checkbox-input + label:placeholder,
.form .option-input + label:placeholder,
.form .text-input:placeholder,
.form .textarea:placeholder,
.form .select:placeholder {
  color: rgba(17, 47, 145, 1);
}

.form .checkbox-input + label:-webkit-autofill,
.form .option-input + label:-webkit-autofill,
.form .text-input:-webkit-autofill,
.form .textarea:-webkit-autofill,
.form .text-input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px rgba(17, 47, 145, 0.3) inset;
  -webkit-text-fill-color: white;
  border-top-color: rgba(17, 47, 145, 0.3);
  border-left-color: rgba(17, 47, 145, 0.3);
  border-right-color: rgba(17, 47, 145, 0.3);
}

.form .checkbox-input,
.form .option-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.form .checkbox-input + label,
.form .option-input + label {
  display: inline-block;
  width: auto;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  transition: 0.4s all;
}

.form .checkbox-input:focus + label,
.form .option-input:focus + label,
.form .checkbox-input:active + label,
.form .option-input:active + label {
  background-color: rgba(17, 47, 145, 0.7);
  color: rgba(17, 189, 186, 0.5);
}

.form .checkbox-input:checked + label,
.form .option-input:checked + label {
  color: white;
  background-color: rgba(17, 47, 145, 0.7);
}

.form .option-label:hover,
.form .option-label:active,
.form .select:hover,
.form .select:active,
.form .textarea:hover,
.form .textarea:active,
.form input:hover,
.form input:active {
  color: rgba(17, 47, 145, 1);
  border-color: rgba(17, 47, 145, 1);
  background-color: rgba(17, 189, 186, 0.5);
}

.form .button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background: rgba(17, 47, 145, 0.8);
  border: 1px solid rgba(17, 47, 145, 0.8);
  color: white;
  font-weight: bold;
  width: auto;
  margin-left: auto;
  font-weight: bold;
  padding-left: 2em;
  padding-right: 2em;
  transition: 0.4s all;
}

.form .button:hover,
.form .button:focus,
.form .button:active {
  color: rgba(17, 47, 145, 1);
  background-color: rgba(17, 189, 186, 1);
}

.form {
  max-width: 45rem;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
}

.form--login {
  max-width: 22rem;
}

.form .field {
  width: 100%;
  margin: 0 0 1.5em 0;
}

@media screen and (min-width: 40rem) {
  .form .field.half {
    width: calc(50% - 1px);
  }
}

.form .field.last {
  margin-left: auto;
}

.form .textarea {
  max-width: 100%;
  resize: none;
}

.form .select {
  text-indent: 0.01px;
  text-overflow: "" !important;
}

.form .select::-ms-expand {
  display: none;
}

.form .checkboxes,
.form .options {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
}

.form .checkbox,
.form .option {
  float: left;
  margin: 1px;
}

.emoticono {
  font-size: 1.75em;
  padding-right: 0.3rem;
}

/* Vue-Selector */
.style-chooser .vs__dropdown-toggle {
  background-color: rgba(17, 47, 145, 0.17);
  color: rgba(17, 47, 145, 1);
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__selected-options .vs__search {
  background: none;
  border: none;
}

.style-chooser .vs__selected {
  color: rgba(17, 47, 145, 1);
}

/*.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu*/
</style>
