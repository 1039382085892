<!--/* eslint-disable-->
<template>
  <section class="seccion-componente">
    <div id="nombre-login" class="nombre-seccion">Login</div>

    <div id="login" class="contenido-seccion">
      <!--eslint-disable-next-line prettier/prettier-->
      <form id="form--login" class="form" @submit.prevent="login">
        <!--error-->
        <div v-if="this.$store.state.loginError" class="login--error">
          <i class="fas fa-exclamation-circle"></i>
          &nbsp;&nbsp;Usuario o contraseña incorrecto
        </div>

        <!--usuario-->
        <p class="field required">
          <label class="label" for="usuario_toi"> <i class="fas fa-user"></i>&nbsp;&nbsp;Usuario </label>
          <input
            class="text-input"
            id="usuario_toi"
            name="usuario_toi"
            required
            type="text"
            v-model="usuarioQueLoggea"
          />
        </p>

        <!--password-->
        <p class="field required">
          <label class="label" for="pass_usuario"> <i class="fas fa-key"></i>&nbsp;&nbsp;Contraseña </label>
          <input
            class="text-input"
            id="pass_usuario"
            name="pass_usuario"
            required
            :type="tipoInput"
            v-model="passQueLoggea"
          />
        </p>
        <i class="fas fa-eye eye" @click="cambiarTipoInput" v-show="this.esTipoPassword"></i>
        <i class="fas fa-eye-slash eye" @click="cambiarTipoInput" v-show="!this.esTipoPassword"></i>

        <p class="field half">
          <button class="button" type="submit"><i class="fas fa-sign-in-alt"></i>&nbsp;&nbsp;Acceder</button>
        </p>
      </form>
    </div>
  </section>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapState } from "vuex";
import router from "@/router";

export default {
  name: "Login",
  data() {
    return {
      usuarioQueLoggea: null,
      passQueLoggea: null
    };
  },
  created() {
    this.$store.commit("cambiarTipoPasswordBoolean", true);
    this.$store.commit("cambiarTipoInput", "password");
  },
  computed: {
    ...mapState(["esTipoPassword", "tipoInput"])
  },
  methods: {
    cambiarLoginSuccess(success) {
      this.$store.commit("cambiarLoginSuccess", success);
    },
    cambiarLoginError(error) {
      this.$store.commit("cambiarLoginError", error);
    },
    login() {
      let payload = {
        usuario: this.usuarioQueLoggea,
        password: this.passQueLoggea
      };
      axios
        .post(`../api/api-toi/login.php`, payload)
        .then(resp => {
          if (resp.data !== "") {
            this.$store.state.usuarioLoggeado = resp.data[0];
            this.cambiarLoginSuccess(true);
            this.cambiarLoginError(false);
            router.push("/nueva-reunion");
          } else {
            this.cambiarLoginError(true);
            this.passQueLoggea = "";
          }
        })
        .catch(err => {
          this.$swal({
            title: "Error al conectar a la base de datos",
            type: "error",
            text: err,
            showConfirmButton: true
            /* timer: 2500 */
          });
        });
    },
    // cambiar tipo password
    cambiarTipoInput() {
      this.$store.commit("cambiarTipoPassword");
      if (!this.esTipoPassword) {
        this.$store.commit("cambiarTipoInput", "text");
      } else {
        this.$store.commit("cambiarTipoInput", "password");
      }
    }
  }
};
</script>

<style scoped>
form {
  padding-top: 30px;
}

.form .label,
.form .text-input,
.form .button {
  padding: 0.7em 1em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  line-height: normal;
  border-radius: 0;
  border: none;
  background: none;
  display: block;
  cursor: pointer;
}

/* Etiquetas del formulario */
.form .label {
  font-family: Rubik;
  color: rgba(17, 47, 145, 1);
  padding: 3px 12px 4px 0;
  letter-spacing: 0.025em;
  font-size: 0.9em;
  font-weight: normal;
  line-height: 1.25;
  position: relative;
  z-index: 100;
}

/* Campos requeridos */
.required .form .label:after,
.form .required .label:after {
  content: " *";
  color: #ec2f36;
  font-weight: bolder;
  font-size: 1.25em;
  vertical-align: top;
}

/* Inputs */
.form .text-input {
  font: inherit;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  background: rgba(17, 47, 145, 0.18);
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 1);
  color: rgba(17, 47, 145, 1);
  position: relative;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

.form .text-input:placeholder {
  color: rgba(17, 47, 145, 1);
}

.form .text-input:-webkit-autofill {
  box-shadow: 0 0 0px 1000px rgba(17, 47, 145, 0.3) inset;
  -webkit-text-fill-color: white;
  border-top-color: rgba(17, 47, 145, 0.3);
  border-left-color: rgba(17, 47, 145, 0.3);
  border-right-color: rgba(17, 47, 145, 0.3);
}

.form input:hover,
.form input:active {
  color: rgba(17, 47, 145, 1);
  border-color: rgba(17, 47, 145, 1);
  background-color: rgba(17, 189, 186, 0.5);
}

.form .button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background: rgba(17, 47, 145, 0.8);
  border: 1px solid rgba(17, 47, 145, 0.8);
  color: white;
  font-weight: bold;
  width: auto;
  margin-left: auto;
  font-weight: bold;
  padding-left: 2em;
  padding-right: 2em;
  -webkit-transition: 0.4s all;
  transition: 0.4s all;
}

.form .button:hover,
.form .button:focus,
.form .button:active {
  color: rgba(17, 47, 145, 1);
  background-color: rgba(17, 189, 186, 1);
}

.form {
  max-width: 720px;
  max-width: 45rem;
  margin: 0 auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#form--login {
  width: 100%;
  max-width: 448px;
  max-width: 28rem;
}

.form .field {
  width: 100%;
  margin: 0 0 1.5em 0;
}

@media screen and (min-width: 40rem) {
  .form .field.half {
    width: calc(50% - 1px);
  }
}

.form .field.last {
  margin-left: auto;
}

.login--error {
  width: 100%;
  padding: 1em 1em;
  margin-bottom: 2em;
  background-color: rgba(236, 47, 54, 0.25);
  color: #ec2f36;
  border-radius: 4px;
  border-bottom: 2px solid #ec2f36;
  font-size: 0.85em;
}

/* mostrar contraseña */
.eye {
  margin: 0;
  padding: 11.2px;
  padding: 0.7rem;
  overflow: hidden;
  position: relative;
  top: -93px;
  left: 90%;
  color: rgba(17, 47, 145, 1);
  cursor: pointer;
}
</style>
