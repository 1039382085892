<template>
  <!--eslint-disable-->
  <div class="reunion">
    <!---->
    <!---->
    <!--cabecera de la reunión (lo que se muestra sin desplegar)-->
    <section class="reunion__cabecera">
      <div class="item-reunion id">
        <span>ID</span>
        {{ reunion.id }}
      </div>
      <div class="item-reunion fechaR">
        <span>FECHA</span>
        {{ reunion.dateR | filtrarFecha }}
      </div>
      <div class="item-reunion horaR">
        <span>HORA</span>
        {{ reunion.dateR | filtrarHora }}
      </div>
      <div class="item-reunion nombreR" @click="mostrarContenidoReunion">
        {{ reunion.nombreR }}
        <br />
        <p class="usu_creador" v-show="this.usuarioLoggeado.perfil != 'user'">
          > {{ reunion.usu_creador }}
          <span
            v-if="(this.usuarioLoggeado.perfil == 'root' || this.usuarioLoggeado.perfil == 'admin') && reunion.usu_responsable != ''"
            class="span__resp"
          >({{ reunion.usu_responsable }})</span>
        </p>
      </div>
      <div class="item-reunion puntuacionR" :class="checkPuntuacion1">
        <i :class="checkPuntuacion3" class="emoticono"></i>
      </div>
      <button type="submit" class="eliminarReunion" @click.prevent="eliminarReunion(reunion.id)">
        <i class="fas fa-trash-alt"></i>
      </button>
    </section>
    <!--...fin Cabecera-->

    <!---->
    <!---->
    <!--contenido de la reunión (lo que se muestra desplegado)-->
    <transition-group id="span__transicion" name="show-content-reunion">
      <!--eslint-disable-next-line-->
      <section class="reunion__contenido" :key="reunion.id" ref="divContenidoReunion">
        <div>
          <div class="item-reunion__contenido">
            <span>Solicitud reunión:&nbsp;</span>
            {{ reunion.solicitaR }}
          </div>
          <div class="item-reunion__contenido">
            <span>Tipo de contacto:&nbsp;</span>
            {{ reunion.tContactoR }}
          </div>
          <div class="item-reunion__contenido">
            <span>Lugar de la reunión:&nbsp;</span>
            {{ reunion.lugarR }}
          </div>
          <div class="item-reunion__contenido">
            <span>Cliente:&nbsp;</span>
            {{ reunion.clienteR }}
          </div>
          <div class="item-reunion__contenido">
            <span>Informe:&nbsp;</span>
            <textarea class="textarea-informe" rows="4" v-model="reunion.informeR" readonly></textarea>
          </div>

          <!--observaciones-->
          <div v-if="Object.keys(reunion.observacionesR).length > 0">
            <div
              class="item-reunion__contenido div__observaciones"
              v-for="(ob, index) in reunion.observacionesR"
              :key="ob.id_observacion"
            >
              <span>Observación {{ Number(index) + 1 }}:&nbsp;</span>
              <textarea
                class="textarea-observaciones"
                rows="4"
                v-model="reunion.observacionesR[index].observacion"
                readonly
              ></textarea>
            </div>
          </div>
          <!--...fin observaciones-->

          <div class="item-reunion__contenido" :class="checkPuntuacion2">
            <span>
              Puntuación:&nbsp;
              {{ reunion.puntuacionR | filtrarPuntuacion }}
            </span>
          </div>
          <!--boton de envio a responsable-->
          <form class="form" @submit.prevent="enviarAResponsable(reunion.id)">
            <p class="field">
              <button id="btn-enviar-responsable" class="button" type="submit">
                <i class="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar observación
              </button>
            </p>
          </form>

          <a class="btn--cerrar" @click.prevent="cerrarContenidoReunion">
            <i class="far fa-times-circle"></i> cerrar
          </a>
        </div>
      </section>
    </transition-group>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "Reunion",
  props: ["reunion"],
  data() {
    return {
      respSelec: [],
      observacion: "",
      respuesta: ""
    };
  },
  computed: {
    ...mapState(["confs", "responsables", "usuarioLoggeado"]),
    checkPuntuacion1() {
      let p = this.reunion.puntuacionR;
      if (p == "M") {
        return "puntMala";
      } else if (p == "R") {
        return "puntRegular";
      } else if (p == "B") {
        return "puntBuena";
      } else if (p == "MB") {
        return "puntMuyBuena";
      } else {
        return "puntExcelente";
      }
    },
    checkPuntuacion2() {
      let p = this.reunion.puntuacionR;
      if (p == "M") {
        return "puntMalaTF";
      } else if (p == "R") {
        return "puntRegularTF";
      } else if (p == "B") {
        return "puntBuenaTF";
      } else if (p == "MB") {
        return "puntMuyBuenaTF";
      } else {
        return "puntExcelenteTF";
      }
    },
    checkPuntuacion3() {
      let p = this.reunion.puntuacionR;
      if (p == "M") {
        return "far fa-sad-cry";
      } else if (p == "R") {
        return "far fa-frown-open";
      } else if (p == "B") {
        return "far fa-grin";
      } else if (p == "MB") {
        return "far fa-grin-beam";
      } else {
        return "far fa-grin-stars";
      }
    }
  },
  methods: {
    // Mostrar div contenido reunión
    mostrarContenidoReunion() {
      $(this.$refs.divContenidoReunion).slideToggle(300);
    },
    // Cerrar div contenido reunión
    cerrarContenidoReunion() {
      // eslint-disable-next-line
      $(this.$refs.divContenidoReunion).slideUp(300);
    },
    // Cargar reuniones en array
    axiosReuniones() {
      this.$store.dispatch("axiosReuniones");
    },
    // Enviar email a responsable/s de las observaciones importantes
    enviarAResponsable(reunion) {
      this.respSelec.length = 0;
      this.$swal({
        title: "Enviar Observación",
        html: `
        <p>Selecciona el responsable/s a enviar la observación</p>
        <form id="form-resp">
          <ul style="list-style:none;text-align:left;">
            <p>Responsables</p>
            <li><input type="checkbox" id="resp-6" class="mi-checkbox" value="6"/> ANTONIO APARICIO PEÑA</li>
            <li><input type="checkbox" id="resp-16" class="mi-checkbox" value="16"/> ENRIQUE FANTOVA PUYALTO</li>
            <li><input type="checkbox" id="resp-33" class="mi-checkbox" value="33"/> FÉLIX VALDEMOROS SAN EMETERIO</li>
            <li><input type="checkbox" id="resp-34" class="mi-checkbox" value="34"/> OLGA VICENTE MORENO</li>
            <li><input type="checkbox" id="resp-42" class="mi-checkbox" value="42"/> DIEGO FRANCO ESTEBAN</li>
            <li><input type="checkbox" id="resp-47" class="mi-checkbox" value="47"/> JESÚS OLIVÁN GASCÓN</li>
            <li><input type="checkbox" id="resp-49" class="mi-checkbox" value="49"/> JAVIER ROBA FONTAN</li>
            <li><input type="checkbox" id="resp-59" class="mi-checkbox" value="59"/> ESPERANZA HORCAS DÍAZ</li>
            <li><input type="checkbox" id="resp-60" class="mi-checkbox" value="60"/> ISABEL MEDEL VAZQUEZ</li>
            <li><input type="checkbox" id="resp-61" class="mi-checkbox" value="61"/> ÁNGEL TARANCÓN LAPEÑA</li>
            <li><input type="checkbox" id="resp-62" class="mi-checkbox" value="62"/> FERNANDO GIMENEZ DE MUÑANA LUENG</li>
            <li><input type="checkbox" id="resp-63" class="mi-checkbox" value="63"/> Mª DEL MAR LAFUENTE TORRES</li>
            <li><input type="checkbox" id="resp-64" class="mi-checkbox" value="64"/> CARMELO LED DOMINGUEZ</li>
            <li><input type="checkbox" id="resp-65" class="mi-checkbox" value="65"/> LUIS ALBERTO GIMENEZ DE MUÑANA LUENG</li>
            <br />
            <p>Administradoras</p>
            <li><input type="checkbox" id="resp-2" class="mi-checkbox" value="2"/> ALEJANDRA BEORTEGUI DÍAZ</li>
            <li><input type="checkbox" id="resp-65" class="mi-checkbox" value="66"/> MARIA VICTORIA DEZA ENCISO</li>
          </ul>

          <br />
          <label for="observacion" style="padding-top:15px;">Escribe una observación:</label><br />
          <textarea id="texta-observacion" name="observacion" rows="8" maxlength="250" style="width:100%;resize:none;border-radius:4px;padding:0.5rem;"></textarea>
        </form>`,
        focusConfirm: false,
        preConfirm: () => {
          let inputs = document.getElementsByClassName("mi-checkbox");
          let observacion = document.getElementById("texta-observacion");
          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
              this.respSelec.push(inputs[i].value);
            }
          }
          this.observacion = observacion.value;
        },
        showCancelButton: true,
        showCloseButton: false,
        confirmButtonText: "Enviar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          console.log("proceso de envio...");
          let payload = {
            id_usuario: this.usuarioLoggeado.id_usuario,
            id_reunion: reunion,
            id_responsables: this.respSelec,
            observacion: this.observacion
          };
          axios
            .post(`../api/api-toi/enviar-email.php`, payload)
            .then(resp => {
              console.log(resp.data);
              this.$swal({
                title: "Observación enviada correctamente",
                type: "success",
                showConfirmButton: false,
                timer: 1800
              });
              this.axiosReuniones();
            })
            .catch(err => {
              this.$swal({
                title: "Error al enviar la observación",
                type: "error",
                text: err,
                showConfirmButton: true,
                confirmButtonText: "Cerrar"
                /* timer: 1800 */
              });
            });
        }
      });
    },
    // Eliminar una reunión
    eliminarReunion(id) {
      this.$swal({
        title: "¿Deseas eliminar la reunión?",
        text: "Una vez eliminada no podrá ser recuperada",
        type: "warning",
        showCancelButton: true,
        showCloseButton: false,
        showLoaderOnConfirm: false,
        allowEnterKey: true,
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          axios
            .post(`../api/api-toi/delete_reuniones.php`, {
              id_reunion: id
            })
            .then(() => {
              this.$swal({
                title: `Reunión ${this.reunion.id} eliminada correctamente`,
                type: "success",
                showConfirmButton: false,
                timer: 1800
              });
              this.axiosReuniones();
            })
            .catch(err => {
              this.$swal({
                title: "Error al eliminar la reunión",
                type: "error",
                text: err,
                showConfirmButton: true,
                confirmButtonText: "Cerrar"
                /* timer: 1800 */
              });
            });
        }
      });
    }
  },
  filters: {
    filtrarFecha: value => {
      value = value
        .substr(0, 10)
        .split("-")
        .reverse()
        .join("/");
      return value;
    },
    filtrarHora: value => {
      value = value.substr(11, 8);
      return value;
    },
    filtrarPuntuacion: value => {
      switch (value) {
        case "M":
          return "Mala";
          break;
        case "R":
          return "Regular";
          break;
        case "B":
          return "Buena";
          break;
        case "MB":
          return "Muy Buena";
          break;
        case "E":
          return "Excelente";
          break;
      }
    },
    capitalizar: value => {
      return value.toUppercase();
    }
  }
};
</script>

<style scoped>
.reunion {
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  background: rgba(17, 47, 145, 0.8);
  color: aliceblue;
  border-bottom: 1px solid aliceblue;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.reunion__cabecera {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 12fr 36px 36px;
  grid-template-rows: 1fr;
  grid-gap: 4px;
  padding: 8px;
}

.item-reunion {
  padding: 2px 10px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}

.item-reunion span {
  font-size: 0.75em;
  color: rgba(240, 248, 255, 0.5);
}

.item-reunion span.span__resp {
  color: #fff;
  font-size: 0.9em;
}

.reunion .id {
  grid-column: 1/2;
  grid-row: auto;
  color: aliceblue;
  font-weight: bold;
  font-size: 0.85em;
}
.reunion .fechaR {
  grid-column: 2/3;
  grid-row: auto;
  color: aliceblue;
  border-left: 1px solid rgba(240, 248, 255, 0.5);
  font-weight: bold;
  font-size: 0.85em;
}
.reunion .horaR {
  grid-column: 3/4;
  grid-row: auto;
  color: aliceblue;
  border-left: 1px solid rgba(240, 248, 255, 0.5);
  font-weight: bold;
  font-size: 0.85em;
}
.reunion .nombreR {
  padding: 0.5em;
  grid-column: 4/5;
  grid-row: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: aliceblue;
  border-left: 1px solid rgba(240, 248, 255, 0.5);
  font-weight: bold;
  font-size: 0.86em;
  cursor: pointer;
}

.reunion .nombreR p.usu_creador {
  color: rgb(17, 189, 186);
  font-weight: bold;
  font-size: 0.82em;
}

.reunion .puntuacionR {
  grid-column: 5/6;
  grid-row: auto;
  font-size: 1em;
  font-weight: bold;
  margin: 0.3em 0.2em;
  padding: 0.3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reunion .eliminarReunion {
  grid-column: 6/7;
  grid-row: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.88em;
  outline: none;
}

.reunion .eliminarReunion:hover {
  color: #ec2f36;
}

/* Reunión contenido */
#span__transicion {
  background: rgba(255, 255, 255, 0.8);
  overflow: hidden;
  max-width: 100%;
}

.reunion__contenido {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  display: none;
  padding: 1.5em;
  color: rgba(17, 47, 145, 0.8);
}

.item-reunion__contenido {
  width: 100%;
  position: relative;
  background: rgba(17, 47, 145, 0.12);
  border-radius: 8px;
  display: flex;
  padding: 0.5em;
  margin-bottom: 0.3em;
  font-size: 0.9em;
}

.item-reunion__contenido span {
  font-weight: bold;
  color: rgba(17, 47, 145, 1);
}

/* boton cierre contenido reunión */
.reunion__contenido a.btn--cerrar {
  cursor: pointer;
  color: rgba(17, 47, 145, 0.8);
  font-weight: bold;
  font-size: 0.9em;
  width: auto;
  float: right;
  padding: 0 3px;
}

.textarea-informe,
.textarea-observaciones {
  width: 60%;
  resize: none;
  margin: 0.2rem 0.7rem;
  padding: 0.7rem;
  border: 2px solid rgba(17, 47, 145, 0.7);
  border-radius: 8px;
  background-color: rgba(17, 47, 145, 0.1);
  font-family: "Rubik";
  font-size: 1em;
  color: rgba(17, 47, 145, 0.8);
}

.textarea-informe {
  width: 100%;
}

.div__observaciones {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.form {
  padding: 0;
  margin: 2rem 0 0 0;
}

.form .button {
  margin: 0;
}

/* Puntuaciones */
.emoticono {
  font-size: 1.5em;
  padding: 0;
}

.puntMala {
  color: red;
}
.puntRegular {
  color: darkgoldenrod;
}
.puntBuena {
  color: deepskyblue;
}
.puntMuyBuena {
  color: limegreen;
}
.puntExcelente {
  color: gold;
}

.puntMalaTF {
  color: white;
  background-color: white;
  background-color: rgba(255, 0, 0, 0.8);
}
.puntRegularTF {
  color: white;
  background-color: white;
  background-color: rgba(184, 134, 11, 0.8);
}
.puntBuenaTF {
  color: white;
  background-color: white;
  background-color: rgba(0, 191, 255, 0.8);
}
.puntMuyBuenaTF {
  color: white;
  background-color: white;
  background-color: rgba(50, 205, 50, 0.8);
}
.puntExcelenteTF {
  color: rgba(17, 47, 145, 1);
  background-color: white;
  background-color: rgba(255, 215, 0, 0.7);
}
</style>
