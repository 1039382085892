<template>
  <div id="app">
    <!--header-->
    <app-header></app-header>

    <!--main-->
    <router-view />

    <!--footer-->
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from "./components/App-header.vue";
import AppFooter from "./components/App-footer.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter
  }
};
</script>

<style>
/* font-awesome */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.9.0/css/all.css");

/* base */
@import "./css/_resets.css";
@import "./css/_tipografia.css";

/* themes */
@import "./css/_colors.css";

/* layout */
@import "./css/_grid.css";
@import "./css/_section.css";

/* media queries */
@import "./css/_media-query.css";
</style>
